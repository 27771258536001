import React, { useState, useEffect } from "react";
import MainLayout from "../../components/layout/mainLayout";
import { APIServices } from "../../api";
import moment from "jalali-moment";
import Modal from "../../components/shared/modal";
import { AiOutlineLoading } from "react-icons/ai";
import snack from "../../utils/snack";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { ReactComponent as Nocontent } from "../../components/assets/nocontent.svg";

export default function IpPage() {
  const [allIP, setAllIP] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [loadingNewIP, setLoadingNewIP] = useState(false);
  const [limitcount, setlimitcount] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const getData = () => {
    APIServices.getAllUserIP().then((res) => {
      setAllIP(res.data);
    });
  };

  const clickDelete = (id) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  const handleAddIP = () => {
    setLoadingNewIP(true);
    APIServices.handleAddNewIP({ address: ipAddress })
      .then((res) => {
        snack.success("IP added successfully.");
        getData();
        setShowModalAdd(false);
        setIpAddress("")
      })
      .finally((e) => {
        setLoadingNewIP(false);
      });
  };

  const handleDeleteIP = () => {
    APIServices.deleteAnIp(selectedId)
      .then((res) => {
        snack.success("Deleted successfully.");
        getData();
        setSelectedId("")
      })
      .finally((e) => {
        setDeleteModal(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <MainLayout>
      <Modal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        title={"Delete a new ip address"}
      >
        <div className="flex flex-col gap-8 w-full my-2">
          <div className="text-base">Are you sure you want to delete IP ?</div>
          <div className="grid grid-cols-2 gap-2">
            <button
              disabled={loadingDelete}
              onClick={handleDeleteIP}
              className="rounded-md px-2 h-9 bg-red-500 text-white"
            >
              {loadingDelete ? (
                <AiOutlineLoading className="mx-auto animate-spin" />
              ) : (
                " Yes, delete it"
              )}
            </button>
            <button
              disabled={loadingDelete}
              onClick={() => {
                setDeleteModal(false);
              }}
              className="link-auth-register !h-9 col-span-1 !w-full"
            >
              No, cancel it
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showModalAdd}
        onClose={() => {
          setShowModalAdd(false);
          setIpAddress("")
        }}
        title={"Add a new ip address"}
      >
        <div className="flex flex-col gap-8 w-full my-2">
          <input
            type="text"
            minLength={4}
            className="w-full h-12 px-2 rounded-sm bg-transparent border border-gray-300 focus:border-gray-400 focus:outline-none text-black"
            placeholder="127.1.1.1,127.2.2.2, ..."
            value={ipAddress}
            onChange={(e) => {
              setIpAddress(e.target.value);
            }}
          />
          {/* <input
            type="number"
            className="w-full h-12 px-2 rounded-sm bg-transparent border border-gray-300 focus:border-gray-400 focus:outline-none text-black"
            placeholder="limit count"
            value={limitcount}
            onChange={(e) => {
              setlimitcount(e.target.value);
            }}
          /> */}
          <button
            disabled={loadingNewIP}
            onClick={handleAddIP}
            className="link-auth-register !w-full"
          >
            {loadingNewIP ? (
              <AiOutlineLoading className="mx-auto animate-spin" />
            ) : (
              " Create"
            )}
          </button>
        </div>
      </Modal>
      <div className="flex justify-between items-center mb-4">
        <div className="text-base mb-2 ml-1">IP</div>
        <button
          onClick={() => {
            setShowModalAdd(true);
          }}
          className="link-auth-register"
        >
          Add new IP
        </button>
      </div>
      <div className="grid grid-cols-1 card">
        <div className="my-6 text-sm">
          For using platform you should add your white list ips include IPV4 and
          IPV6. If you want to make transaction from any ip fill * in address
          field then submit.
        </div>
        <table className="w-full">
          <thead>
            <tr className="h-10 bg-slate-300 rounded-tr-md rounded-br-md">
              <th className="whitespace-nowrap">#</th>
              <th className="whitespace-nowrap">Address</th>
               <th className="whitespace-nowrap">Create at</th>
              {/*<th className="whitespace-nowrap">Limit count</th>
              <th className="whitespace-nowrap">Request count</th> */}
              <th className="whitespace-nowrap">Action</th>
            </tr>
          </thead>
          <tbody className="px-3">
            {allIP?.map((ip, index) => (
              <tr className="h-10">
                <td className="px-3 whitespace-nowrap text-center">
                  {index + 1}
                </td>
                <td className="px-3 whitespace-nowrap text-center">
                  {ip.address}
                </td>
                 <td className="px-3 whitespace-nowrap text-center">
                  {moment(ip?.createddate).format("jYYYY/jMM/jDD hh:mm")}
                </td>
                {/*<td className="px-3 whitespace-nowrap text-center">
                  {ip.limitcount}
                </td>
                <td className="px-3 whitespace-nowrap text-center">
                  {ip.requestcount}
                </td> */}
                <td className="px-3 whitespace-nowrap text-center">
                  <DeleteRoundedIcon
                    className="cursor-pointer text-[#ef6a6a]"
                    onClick={() => {
                      clickDelete(ip.id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!allIP?.length && (
          <div className="flex justify-center items-center min-h-[420px] flex-col">
            <Nocontent />
            <div className="text-center">No data found!</div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}
