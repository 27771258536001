import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../assets/fulllogo.svg";

export default function Header() {
  const user = useSelector((state) => state.user);
  return (
    <div className="min-h-12 py-2.5 px-4 border-b flex justify-between items-center bg-white">
      <div className="flex items-center">
        <img src={Logo} className="mr-5" />
        <Link
          to={"https://doc.transferbyme.com/documentation"}
          target="_blank"
          className="link-auth-login"
        >
          Document
        </Link>
        <Link to={"/plans"} className="link-auth-login ml-3">
          Plans
        </Link>
        <Link to={"/estimate"} className="link-auth-login ml-3">
          Estimate
        </Link>
      </div>
      <div className="flex items-center">
        {localStorage.getItem("token") ? (
          <>
            <Link to={"/dashboard"} className="link-auth-login">
              Dashboard
            </Link>
          </>
        ) : (
          <div className="flex gap-8 mr-14 items-center">
            <Link to={"/login"} className="link-auth-login">
              Login
            </Link>
            <Link to={"/register"} className="link-auth-register">
              Register
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
