import { useFormik } from "formik";
import React, { useState } from "react";
import { ReactComponent as Shield } from "../../components/assets/shield-tick.svg";
import * as Yup from "yup";
import Password from "../../components/assets/password.svg";
import Show from "../../components/assets/show.svg";
import Hide from "../../components/assets/hide.svg";
import { AiOutlineLoading } from "react-icons/ai";
import { APIServices } from "../../api";
import snack from "../../utils/snack";

export default function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const initialValues = {
    newPassword: "",
    password: "",
    confirmpassword: "",
  };

  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        {
          message: "",
          excludeEmptyString: false,
        }
      )
      .min(8, "")
      .trim()
      .required(),
    confirmpassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        {
          message: "",
          excludeEmptyString: false,
        }
      )
      .oneOf(
        [Yup.ref("newPassword"), null],
        "repeat is not match with newPassword"
      )
      .min(8)
      .required("newPassword"),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        {
          message: "",
          excludeEmptyString: false,
        }
      )
      .oneOf(
        [Yup.ref("confirmpassword"), null],
        "repeat is not match with confirmpassword"
      )
      .min(8)
      .required("confirmpassword"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleSubmitForm(values, setSubmitting, resetForm);
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
    validateForm,
    setSubmitting,
  } = formik;

  const handleSubmitForm = (values, setSubmitting, resetForm) => {
    // console.log(values.email.split("@")[0]);
    setSubmitting(true);
    const dto = { password: values.password, newPassword: values.newPassword };
    APIServices.handleChanegPasswordLogedinUser(JSON.stringify(dto))
      .then((res) => {
        if (res.data.success) {
          setSubmitting(false);
          resetForm()
          snack.success(res.data.message);
        }
      })
      .catch((e) => {
        setSubmitting(false);
        snack.warning(e.data.message)
      });
  };
  return (
    <>
      <div className="flex gap-1 items-center">
        <Shield />
        <span>Change Password</span>
      </div>
      <div className="px-20 mt-8 mb-12 gap-8 col-span-6">
        <form
          onSubmit={formik.handleSubmit}
          className="w-full"
          autoComplete="off"
        >
          <div className="input-container !w-80">
            <input
              value={values.password}
              placeholder="Password"
              type={!showPassword ? "password" : "text"}
              className={`!w-80 ${
                touched.password && errors.password ? "error" : ""
              }`}
              onChange={(e) => setFieldValue("password", e.target.value)}
            />
            <img src={Password} className="icon" />
            {showPassword ? (
              <img
                src={Hide}
                className="icon-2"
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
              />
            ) : (
              <img
                src={Show}
                className="icon-2"
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
              />
            )}
          </div>
          <div className="input-container !w-80">
            <input
              value={values.newPassword}
              placeholder="New Password"
              type={!showNewPassword ? "password" : "text"}
              className={`!w-80 ${
                touched.newPassword && errors.newPassword ? "error" : ""
              }`}
              onChange={(e) => setFieldValue("newPassword", e.target.value)}
            />
            <img src={Password} className="icon" />
            {showNewPassword ? (
              <img
                src={Hide}
                className="icon-2"
                onClick={() => {
                  setShowNewPassword((prev) => !prev);
                }}
              />
            ) : (
              <img
                src={Show}
                className="icon-2"
                onClick={() => {
                  setShowNewPassword((prev) => !prev);
                }}
              />
            )}
          </div>
          <div className="input-container !w-80">
            <input
              value={values.confirmpassword}
              placeholder="Confirm Password"
              type={!showConfirmPassword ? "password" : "text"}
              className={`!w-80 ${
                touched.confirmpassword && errors.confirmpassword ? "error" : ""
              }`}
              onChange={(e) => setFieldValue("confirmpassword", e.target.value)}
            />
            <img src={Password} className="icon" />
            {showConfirmPassword ? (
              <img
                src={Hide}
                className="icon-2"
                onClick={() => {
                  setShowConfirmPassword((prev) => !prev);
                }}
              />
            ) : (
              <img
                src={Show}
                className="icon-2"
                onClick={() => {
                  setShowConfirmPassword((prev) => !prev);
                }}
              />
            )}
          </div>
          {isSubmitting ? (
            <span className="submit-btn !w-80">
              <AiOutlineLoading className="animate-spin" />
            </span>
          ) : (
            <button type="submit" className="submit-btn !w-80">
              Change Password
            </button>
          )}
        </form>
        <div className="h-[1px] mt-20 bg-[#9B9DAC] w-full"></div>
      </div>
    </>
  );
}
