import { useFormik } from "formik";
import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import ReactInputVerificationCode from "react-input-verification-code";
import * as Yup from "yup";
import Email from "../../components/assets/email.svg";
import Hide from "../../components/assets/hide.svg";
import Show from "../../components/assets/show.svg";
import ResendCountDown from "../../utils/ResendCountDown";
import { APIServices } from "../../api";

export default function ForgetPasswordStep2(props) {
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmCode, setConfirmCode] = useState("");
  const [retryCount, setRetryCount] = useState(1);
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const initialValues = {
    password: "",
    otpcode: "",
  };

  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        {
          message: "",
          excludeEmptyString: false,
        }
      )
      .min(8, "")
      .trim()
      .required(),
    otpcode: Yup.string().min(6, "").required(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitForm(values, setSubmitting);
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
    validateForm,
    setSubmitting,
  } = formik;

  const handleSubmitForm = (values) => {
    props.handleConfirmAccount({
      password: values.password,
      code: confirmCode,
    });
  };

  const handleResendEmail = () => {
    setLoadingSendCode(true);
    APIServices.resendEmail({ email: props.email }).then((res) => {
      if (res.data.success) {
        setLoadingSendCode(false);
        setRetryCount((prev) => prev + 1);
      }
    });
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full mt-8"
      autoComplete="off"
    >
      <div
        className={`verification-code-styles verification-code !text-white flex flex-col w-[260px] -mt-4 ${
          errors.otpcode ? "has-error" : ""
        }`}
      >
        <ReactInputVerificationCode
          length={6}
          autoFocus
          onChange={(val) => {
            setConfirmCode(val);
            setFieldValue("otpcode", val);
          }}
          passwordMask="-"
          value={confirmCode}
          placeholder=""
          onCompleted={(e) => {
            // handleConfirmAccount(e);
          }}
        />
        <div className="flex mt-2 justify-center">
          <ResendCountDown
            retryCount={retryCount}
            handleClick={handleResendEmail}
            loading={loadingSendCode}
          />
        </div>
        <div className="input-container mt-4">
          <input
            value={values.password}
            placeholder="Password"
            type={!showPassword ? "password" : "text"}
            className={`${touched.password && errors.password ? "error" : ""}`}
            onChange={(e) => setFieldValue("password", e.target.value)}
          />
          <img src={Email} className="icon" />
          {showPassword ? (
            <img
              src={Hide}
              className="icon-2"
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            />
          ) : (
            <img
              src={Show}
              className="icon-2"
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            />
          )}
        </div>

        {props.loading ? (
          <span type="submit" className="submit-btn">
            <AiOutlineLoading className="animate-spin" />
          </span>
        ) : (
          <button
            // onClick={() => handleConfirmAccount(confirmCode)}
            type="submit"
            className="submit-btn !mt-8"
          >
            Submit
          </button>
        )}
      </div>
    </form>
  );
}
