import { CLEAR_USER, SET_TOKEN } from '../actions/user'

const initialState = {}

function userReducer(state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case CLEAR_USER:
      return initialState
    case SET_TOKEN:
      return action.payload
    default:
      return state
  }
}

export default userReducer
