import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import * as Yup from "yup";
import { APIServices } from "../../api";
import Modal from "../../components/shared/modal";
import snack from "../../utils/snack";
import { commafy2 } from "../../utils/commafy";
import moment from "moment-jalaali";
import moment2 from "moment";
export default function CheckBalance() {
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const initialValues = {
    address: "",
  };

  const ValidationSchema = Yup.object().shape({
    address: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitForm(values, setSubmitting);
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
    validateForm,
    setSubmitting,
  } = formik;

  const handleSubmitForm = (values, setSubmitting) => {
    let dto = {
      address: values.address,
      limit: 10,
    };
    APIServices.checkenergyBalance(dto)
      .then((res) => {
        if (!res.data.data.length) {
          snack.warning("We can't find availabe energy in your wallet.");
        } else {
          setList(res.data.data);
          setShowModal(true);
        }
      })
      .finally((e) => {
        setSubmitting(false);
      });
  };
  return (
    <div>
      <Modal
        onClose={() => {
          setShowModal(false);
          setList([]);
        }}
        open={showModal}
        title={"Energy in your wallet"}
      >
        <div className="flex flex-col gap-8 w-full my-2">
          <table>
            <thead>
              <tr className="h-10">
                <th>Amount</th>
                <th>Transaction count</th>
                <th>Expire</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => (
                <tr className="h-10">
                  <td>{commafy2(item.resourceValue)}</td>
                  <td className="text-center">{item?.count}</td>
                  <td>
                    {item.expireTime == 0 ? "-" : moment.unix(item.expireTime / 1000).format("jYYYY/jMM/jDD HH:MM")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="p-8 rounded-[20px] bg-white shadow-sm my-4 h-[calc(100%-32px)]">
        <div className="font-semibold mb-4">
          Check your wallet energy balance
        </div>
        <div>
          <form
            onSubmit={formik.handleSubmit}
            className="w-full mt-14"
            autoComplete="off"
          >
            <div className="input-container">
              <label className="!font-normal cursor-pointer">
                Your Address
              </label>
              <input
                value={values.address}
                placeholder="address"
                type={"text"}
                className={`!pl-4 ${
                  touched.address && errors.address ? "error" : ""
                }`}
                onChange={(e) => setFieldValue("address", e.target.value)}
              />
              {values.address && (
                <HighlightOffRoundedIcon
                  className="absolute right-2 top-9 cursor-pointer bg-white"
                  onClick={() => {
                    setFieldValue("address", "");
                  }}
                />
              )}
            </div>
            {isSubmitting ? (
              <span type="submit" className="submit-btn">
                <AiOutlineLoading className="animate-spin" />
              </span>
            ) : (
              <button type="submit" className="submit-btn">
                Check
              </button>
            )}
          </form>
        </div>
        <div>
          You can check energy balance of any wallet you need, and also any information about your rent energies,
          How much transaction you can do with these energies and how many you have use .
        </div>
      </div>
    </div>
  );
}
