import React, { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { APIServices } from "../../api";
import MainLayout from "../../components/layout/mainLayout";
import Modal from "../../components/shared/modal";
import snack from "../../utils/snack";
import { ReactComponent as Copy } from "../../components/assets/copy.svg";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Password from "../../components/assets/password.svg";
import { ReactComponent as CopyFill } from "../../components/assets/copy-fill.svg";
import Show from "../../components/assets/show.svg";
import Hide from "../../components/assets/hide.svg";
import { addressHandler } from "../../utils/addressHandler";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
export default function ApiKeyPage() {
  const [showModal, setShowModal] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [keyName, setKeyName] = useState("");
  const [loadingNewPaikey, setLoadingNewPaikey] = useState(false);
  const [seletedKey, setSeletedKey] = useState("");
  const [seletedDeleteKey, setSeletedDeleteKey] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [selectedApiKey, setSelectedApiKey] = useState({});
  const [verifyStep, setVerifyStep] = useState(1);
  const [apikeyResult, setApikeyResult] = useState({});

  const handleGenerateApikey = () => {
    if (keyName.length >= 4) {
      setLoadingNewPaikey(true);
      let temp = {
        name: keyName,
      };

      APIServices.generateApiKeyUser(JSON.stringify(temp))
        .then((res) => {
          // after submit new api key returned
          setShowModal(false);
          getData();
          setKeyName("");
        })
        .finally((e) => {
          setLoadingNewPaikey(false);
        });
    } else {
      snack.warning("Key name should be more than 4 characters.");
    }
  };

  const getData = () => {
    APIServices.getAllApiKeyUser().then((res) => {
      setKeyList(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const setMainApikey = (key) => {
    setSeletedKey(key.id);
    APIServices.updateMainApiKey({ id: key.id })
      .then((res) => {
        if (res.data.success) {
          snack.success(res.data.message);
          getData();
        }
      })
      .finally((e) => {
        setSeletedKey("");
      });
  };

  const handleDelete = (item, ok) => {
    setSeletedDeleteKey(item);
    if (ok) {
      APIServices.deleleApiKey(item.id).then((res) => {
        if (res.data.success) {
          snack.success(res.data.message);
          getData();
          setDeleteModal(false);
        }
      });
    } else {
      setDeleteModal(true);
    }
  };

  const handleshowApikey = (item) => {
    setLoadingNewPaikey(true);
    APIServices.getDetailApiKey({
      password: userPassword,
      id: item.id,
    })
      .then((res) => {
        if (res.data.success) {
          setVerifyStep(2);
          setApikeyResult(res.data.data);
        }
      })
      .finally((e) => {
        setLoadingNewPaikey(false);
      });
  };

  return (
    <MainLayout>
      <Modal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
          setSeletedDeleteKey({});
        }}
        title={"Delete an api key"}
      >
        <div className="flex flex-col gap-8 w-full my-2">
          <div className="text-sm mb-3">
            Are you sure you want delete this apikey?
          </div>
          <div className="grid grid-cols-2 gap-4">
            <button
              disabled={loadingNewPaikey}
              onClick={() => {
                handleDelete(seletedDeleteKey, true);
              }}
              className="link-auth-register !w-full !bg-[#FF3E3E]"
            >
              {loadingNewPaikey ? (
                <AiOutlineLoading className="mx-auto animate-spin" />
              ) : (
                " Delete"
              )}
            </button>
            <button
              disabled={loadingNewPaikey}
              onClick={() => {
                setDeleteModal(false);
                setSeletedDeleteKey({});
              }}
              className="link-auth-register !w-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={detailModal}
        onClose={() => {
          setDetailModal(false);
          setTimeout(() => {
            setVerifyStep(1);
            setUserPassword("");
          }, 1000);
        }}
        title={"Api key detail"}
      >
        {verifyStep == 1 ? (
          <div className="flex flex-col gap-8 w-full my-2">
            <div className="input-container">
              <input
                value={userPassword}
                placeholder="Password"
                type={!showPassword ? "password" : "text"}
                className={``}
                onChange={(e) => setUserPassword(e.target.value)}
              />
              <img src={Password} className="icon" />
              {showPassword ? (
                <img
                  src={Hide}
                  className="icon-2"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                />
              ) : (
                <img
                  src={Show}
                  className="icon-2"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                />
              )}
            </div>
            <button
              disabled={loadingNewPaikey}
              onClick={() => {
                handleshowApikey(selectedApiKey);
              }}
              className="link-auth-register !w-full"
            >
              {loadingNewPaikey ? (
                <AiOutlineLoading className="mx-auto animate-spin" />
              ) : (
                " Submit"
              )}
            </button>
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <div className="flex justify-between text-sm items-center">
              <span>Name</span>
              <span>{apikeyResult.name}</span>
            </div>
            <div className="flex justify-between text-sm items-center">
              <span>Value</span>
              <span className="flex gap-1 items-center">
                <span>{apikeyResult.value}</span>
                <CopyFill
                  className="cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(apikeyResult.value);
                    snack.success("Copied.");
                  }}
                />
              </span>
            </div>
            <div className="flex justify-between text-sm items-center">
              <span>Careated at</span>
              <span>{apikeyResult.date}</span>
            </div>
            <div className="flex justify-between text-sm items-center">
              <span>Main key</span>
              <span>
                {apikeyResult.ismain ? (
                  <CheckCircleOutlineRoundedIcon className="text-[#2BD483]" />
                ) : (
                  <CloseRoundedIcon className="text-[#FF3E3E]" />
                )}
              </span>
            </div>
            <div className="mt-4">
              <button
                className="link-auth-register !w-full"
                onClick={() => {
                  setDetailModal(false);
                  setTimeout(() => {
                    setVerifyStep(1);
                    setUserPassword("");
                  }, 1000);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        title={"Add a new api key"}
      >
        <div className="flex flex-col gap-8 w-full my-2">
          <input
            type="text"
            maxLength={15}
            autoFocus={showModal}
            minLength={4}
            className="w-full h-12 px-2 rounded-sm bg-transparent border border-gray-300 focus:border-gray-400 focus:outline-none text-black"
            placeholder="Api key name ..."
            value={keyName}
            onChange={(e) => {
              setKeyName(e.target.value);
            }}
          />
          <button
            disabled={loadingNewPaikey}
            onClick={handleGenerateApikey}
            className="link-auth-register !w-full"
          >
            {loadingNewPaikey ? (
              <AiOutlineLoading className="mx-auto animate-spin" />
            ) : (
              " Create"
            )}
          </button>
        </div>
      </Modal>
      <div className="flex justify-between items-center">
        <div className="text-base mb-2 ml-1">Api key</div>
        <div>
          {keyList.length < 3 && (
            <button
              onClick={() => {
                setShowModal(true);
              }}
              className="link-auth-register !min-w-52"
            >
              Create new api key
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 my-2 gap-2 bg-white shadow-sm card">
        <div className="items-center">
          Warning : To make a request you should use main apikey that signed
          with{" "}
          <CheckCircleOutlineRoundedIcon className="text-[#2BD483] relative bottom-1" />
          , and if need change main key you can set another one as main key with
          use "Set As Main" button, or for delete and make a new key use trash
          icon and confirm popup then make a new.
        </div>
        {keyList?.map((item, index) => (
          <div className="p-4 rounded-md bg-[#f4f8ff] my-4 mx-2 mb-4">
            <div className="flex justify-between items-center flex-wrap">
              <div
                className={`flex gap-1 ${
                  !item.ismain ? "text-[#9B9DAC]" : "text-black"
                }`}
              >
                <span>{index + 1 + "."}</span>
                {item?.name}
              </div>
              {keyList.length > 0 ? (
                <div className="flex gap-2 items-center">
                  <span
                    className={`text-sm ${
                      !item.ismain ? "text-[#9B9DAC]" : "text-black"
                    }`}
                  >
                    {item?.value}
                  </span>
                  {/* <Copy
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(item.value);
                      snack.success("Copied.");
                    }}
                  /> */}
                  <img
                    src={Show}
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedApiKey(item);
                      setDetailModal(true);
                    }}
                  />
                  {item.ismain ? (
                    <CheckCircleOutlineRoundedIcon className="text-[#2BD483]" />
                  ) : // <span className="">Main</span>
                  seletedKey == item.id ? (
                    <>
                      <button className="link-auth-register !h-6 !text-xs">
                        <AiOutlineLoading className="animate-spin" />
                      </button>
                    </>
                  ) : (
                    <div className="flex items-center gap-1">
                      <button
                        className="link-auth-register !h-6 !text-xs"
                        onClick={() => {
                          setMainApikey(item);
                        }}
                      >
                        Set As Main
                      </button>
                      <DeleteRoundedIcon
                        className="cursor-pointer text-[#FF3E3E]"
                        onClick={() => {
                          handleDelete(item, false);
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>You don't have ani key, create one.</div>
              )}
            </div>
            {/* <div className="flex items-center gap-2 my-2">
            <div className="text-sm">All use : </div>
            <div className="text-sm text-blue-400">11230</div>
          </div> */}
          </div>
        ))}

        {!keyList.length && (
          <div className="w-full flex items-center justify-center min-h-20">
            You don't have ani apikey, generate one.
          </div>
        )}
      </div>
    </MainLayout>
  );
}
