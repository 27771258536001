import { useFormik } from "formik";
import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { APIServices } from "../api";
import Email from "../components/assets/email.svg";
import Hide from "../components/assets/hide.svg";
import Logo from "../components/assets/logo.svg";
import Password from "../components/assets/password.svg";
import Show from "../components/assets/show.svg";
import Header from "../components/shared/header";
import { setUserData } from "../components/store/actions/user";
import RegisterStep2 from "./components/RegisterStep2";
import ReCAPTCHA from "react-google-recaptcha";

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const initialValues = {
    email: "",
    password: "",
    confirmpassword: "",
    captcha: "",
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email().required("email"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        {
          message: "",
          excludeEmptyString: false,
        }
      )
      .min(8, "")
      .trim()
      .required(),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "repeat is not match with password")
      .min(8)
      .required("password"),
    captcha: Yup.string().required(),
  });

  const onChangeCaptcha = (e) => {
    setFieldValue("captcha", e);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitForm(values, setSubmitting);
    },
  });

  const { values, touched, errors, setFieldValue, isSubmitting } = formik;

  const handleSubmitForm = (values, setSubmitting) => {
    // console.log(values.email.split("@")[0]);
    let dto = {
      email: values.email.toLowerCase(),
      password: values.password,
      repeatPassword: values.confirmpassword,
      captcha: values.captcha,
    };
    APIServices.registerAccount(JSON.stringify(dto))
      .then((response) => {
        if (response.data.success) {
          setStep(2);
          setUserId(response.data.userid);
          setUserEmail(values.email);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((e) => {
        setSubmitting(false);
      });
  };

  const finalRegister = (data) => {
    setLoadingConfirm(true);
    let dto = {
      id: userId,
      otpcode: data.code,
    };
    APIServices.verifyRegisterAccount(JSON.stringify(dto))
      .then((response) => {
        // snack.success("Successfully ");
        navigate("/dashboard");
        localStorage.setItem("token", response.data.accessToken);
        dispatch(setUserData(response.data.user));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((e) => {
        setLoadingConfirm(false);
      });
  };

  return (
    <div className="auth-bg">
      <Header />
      <div className="auth-wrapper">
        <div className="flex gap-1 flex-wrap items-center text-[40px] tracking-wider">
          <span className="font-extralight whitespace-nowrap">Welcome to</span>
          <p className="text-[#111111] text-[40px] font-bold whitespace-nowrap">
            TransferByMe
          </p>
        </div>

        <br />
        <div className={`${step == 1 ? " box-auth" : " box-auth"}`}>
          <img className="mx-auto mb-12" src={Logo} />
          {step == 1 ? (
            <form
              onSubmit={formik.handleSubmit}
              className="w-full mt-14"
              autoComplete="off"
            >
              <div className="input-container">
                <input
                  value={values.email}
                  type="email"
                  autoFocus
                  autoComplete="nope"
                  placeholder="Email"
                  className={`${touched.email && errors.email ? "error" : ""}`}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />
                <img src={Email} className="icon" />
              </div>
              <div className="input-container">
                <input
                  value={values.password}
                  placeholder="Password"
                  type={!showPassword ? "password" : "text"}
                  className={`${
                    touched.password && errors.password ? "error" : ""
                  }`}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                />
                <img src={Password} className="icon" />
                {showPassword ? (
                  <img
                    src={Hide}
                    className="icon-2"
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <img
                    src={Show}
                    className="icon-2"
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  />
                )}
                {errors.password && (
                  <span className="text-sm mt-2 text-[#ef6a6a]">
                    Password must be includes number and large and small
                    alphabet and special characters (!@#\$%\^&\*) and more than
                    8 characters.
                  </span>
                )}
              </div>
              <div className="input-container">
                <input
                  value={values.confirmpassword}
                  placeholder="Confirm Password"
                  type={!showConfirmPassword ? "password" : "text"}
                  className={`${
                    touched.confirmpassword && errors.confirmpassword
                      ? "error"
                      : ""
                  }`}
                  onChange={(e) =>
                    setFieldValue("confirmpassword", e.target.value)
                  }
                />
                <img src={Password} className="icon" />
                {showConfirmPassword ? (
                  <img
                    src={Hide}
                    className="icon-2"
                    onClick={() => {
                      setShowConfirmPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <img
                    src={Show}
                    className="icon-2"
                    onClick={() => {
                      setShowConfirmPassword((prev) => !prev);
                    }}
                  />
                )}
                {errors.confirmpassword && (
                  <span className="text-sm mt-2 text-[#ef6a6a]">
                    Confirm password must be equal to password
                  </span>
                )}
              </div>
              <div className="mb-8 flex captchaContainer justify-center flex-col items-center">
                <ReCAPTCHA
                  sitekey="6LdHhtwpAAAAADou_7XeQbOSoSzNyvnVI8jgFAbQ"
                  onChange={onChangeCaptcha}
                />
                {errors.captcha && (
                  <div className="text-[#ef6a6a] text-sm">
                    Captcha is required.
                  </div>
                )}
              </div>
              {isSubmitting ? (
                <span type="submit" className="submit-btn">
                  <AiOutlineLoading className="animate-spin" />
                </span>
              ) : (
                <button type="submit" className="submit-btn">
                  Sign up
                </button>
              )}
              <div className="flex gap-5 items-center flex-wrap mt-6 justify-between">
                <span className="text-[#111111] text-base whitespace-nowrap">
                  Do you have an account ?
                </span>
                <Link
                  to="/login"
                  className="link-auth whitespace-nowrap underline"
                >
                  Login
                </Link>
              </div>
            </form>
          ) : (
            <RegisterStep2
              goBack={() => {
                setStep(1);
              }}
              loadingConfirm={loadingConfirm}
              userId={userId}
              email={userEmail}
              handleConfirmAccount={finalRegister}
            />
          )}
        </div>
      </div>
    </div>
  );
}
