import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/mainLayout";
import { APIServices } from "../../api";
import Header from "../../components/shared/header";
import { useSelector } from "react-redux";

export default function AllRequests() {
  const [priceAndCountList, setPriceAndCountList] = useState([]);
  const [loadingGetPrice, setLoadingGetPrice] = useState(false);
  const [goldList, setGoldList] = useState([]);
  const [silverList, setSilverList] = useState([]);
  const [boronzeList, setBoronzeList] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  const user = useSelector((state) => state.user);

  const getData = () => {
    setLoadingGetPrice(true);
    APIServices.handleEstimatePriceByPlan()
      .then((res) => {
        setGoldList(res.data.data.gold);
        setSilverList(res.data.data.silver);
        setBoronzeList(res.data.data.boronze);
      })
      .finally((e) => {
        setLoadingGetPrice(false);
      });
  };
  const getUserInformations = () => {
    APIServices.getInformationsUser().then((res) => {
      console.log(res.data);
      setUserInfo(res.data.data);
    });
  };

  useEffect(() => {
    getData();
    setInterval(() => {
      getData();
    }, 30000);
    if (user.id) {
      getUserInformations();
    }
  }, []);

  return (
    <>
      <Header />
      <div className="px-2">
        <div className="text-2xl my-2 ml-1">All Plans</div>
        <div className="p-8 rounded-[20px] bg-white shadow-sm my-4">
          <div className="font-medium"> 
            First service - request per transaction
          </div>
          <div className=" inline-block my-2 bg-[#BF9500] px-2 rounded-sm">
             Gold Plan
          </div>
          <div className=" overflow-x-auto">
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="px-3 whitespace-nowrap">Has USDT price</th>
                  {/* <th className="px-3 whitespace-nowrap">
                    Count of availabe 32k
                  </th> */}
                  <th className="px-3 whitespace-nowrap">Saved cost for 32k</th>
                  <th className="px-3 whitespace-nowrap">
                    Does not have USDT price
                  </th>
                  {/* <th className="px-3 whitespace-nowrap">
                    Count of availabe 65k
                  </th> */}
                  <th className="px-3 whitespace-nowrap">Saved cost for 65k</th>
                </tr>
              </thead>
              <tbody>
                {loadingGetPrice
                  ? [0, 1, 2, 3, 4, 5]?.map((item, index) => (
                      <tr key={index}>
                        <td colSpan={6}>
                          <span className="inline-block w-full h-10 rounded-sm animate-pulse bg-gray-300"></span>
                        </td>
                      </tr>
                    ))
                  : goldList?.map((item, index) => (
                      <tr className="h-10">
                        <td className="text-center">{item.price32k} TRX</td>
                        {/* <td className="text-center">{item.amount32k}</td> */}
                        <td className="text-center">{item.save32k} %</td>
                        <td className="text-center">{item.price65k} TRX</td>
                        {/* <td className="text-center">{item.amount65k}</td> */}
                        <td className="text-center">{item.save65k} %</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 inline-block mb-2 bg-[#9799A3] px-2 rounded-sm">
             Silver Plan
          </div>
          <div className=" overflow-x-auto">
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="px-3 whitespace-nowrap">Has USDT price</th>
                  {/* <th className="px-3 whitespace-nowrap">
                    Count of availabe 32k
                  </th> */}
                  <th className="px-3 whitespace-nowrap">Saved cost for 32k</th>
                  <th className="px-3 whitespace-nowrap">
                    Does not have USDT price
                  </th>
                  {/* <th className="px-3 whitespace-nowrap">
                    Count of availabe 65k
                  </th> */}
                  <th className="px-3 whitespace-nowrap">Saved cost for 65k</th>
                </tr>
              </thead>
              <tbody>
                {loadingGetPrice
                  ? [0, 1, 2, 3, 4, 5]?.map((item, index) => (
                      <tr key={index}>
                        <td colSpan={6}>
                          <span className="inline-block w-full h-10 rounded-sm animate-pulse bg-gray-300"></span>
                        </td>
                      </tr>
                    ))
                  : silverList?.map((item, index) => (
                      <tr className="h-10">
                        <td className="text-center">{item.price32k} TRX</td>
                        {/* <td className="text-center">{item.amount32k}</td> */}
                        <td className="text-center">{item.save32k} %</td>
                        <td className="text-center">{item.price65k} TRX</td>
                        {/* <td className="text-center">{item.amount65k}</td> */}
                        <td className="text-center">{item.save65k} %</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 inline-block mb-2 bg-[#BD6D40] px-2 rounded-sm">
             Boronze Plan
          </div>
          <div className=" overflow-x-auto">
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="px-3 whitespace-nowrap">Has USDT price</th>
                  {/* <th className="px-3 whitespace-nowrap">
                    Count of availabe 32k
                  </th> */}
                  <th className="px-3 whitespace-nowrap">Saved cost for 32k</th>
                  <th className="px-3 whitespace-nowrap">
                    Does not have USDT price
                  </th>
                  {/* <th className="px-3 whitespace-nowrap">
                    Count of availabe 65k
                  </th> */}
                  <th className="px-3 whitespace-nowrap">Saved cost for 65k</th>
                </tr>
              </thead>
              <tbody>
                {loadingGetPrice
                  ? [0, 1, 2, 3, 4, 5]?.map((item, index) => (
                      <tr key={index}>
                        <td colSpan={6}>
                          <span className="inline-block w-full h-10 rounded-sm animate-pulse bg-gray-300"></span>
                        </td>
                      </tr>
                    ))
                  : boronzeList?.map((item, index) => (
                      <tr className="h-10">
                        <td className="text-center">{item.price32k} TRX</td>
                        {/* <td className="text-center">{item.amount32k}</td> */}
                        <td className="text-center">{item.save32k} %</td>
                        <td className="text-center">{item.price65k} TRX</td>
                        {/* <td className="text-center">{item.amount65k}</td> */}
                        <td className="text-center">{item.save65k} %</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
