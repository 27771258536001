import React, { useState, useEffect } from "react";
import { APIServices } from "../../api";
import { useFormik } from "formik";
import { AiOutlineLoading } from "react-icons/ai";
import * as Yup from "yup";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import snack from "../../utils/snack";
import { ReactComponent as Copy } from "../../components/assets/copy.svg";
import { addressHandler } from "../../utils/addressHandler";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { ReactComponent as Nocontent } from "../../components/assets/nocontent.svg";
import Modal from "../../components/shared/modal";
export default function SaveAddresses() {
  const [showModal, setShowModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [list, setList] = useState([]);
  const initialValues = {
    address: "",
    name: "",
  };

  const ValidationSchema = Yup.object().shape({
    address: Yup.string().required(""),
    name: Yup.string().required(""),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitForm(values, setSubmitting);
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
    validateForm,
    setSubmitting,
  } = formik;

  const handleSubmitForm = (values, setSubmitting) => {
    let dto = {
      address: values.address,
      name: values.name,
    };
    APIServices.handleAddNewSaveaddress(JSON.stringify(dto))
      .then((res) => {
        snack.success(res.data.message);
        resetForm();
        getData();
      })
      .finally((e) => {
        setSubmitting(false);
      });
  };

  const getData = () => {
    APIServices.handleGetSaveaddress().then((res) => {
      setList(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (id, val) => {
    setSelectedId(id);
    if (!val) {
      setShowModal(true);
    } else {
      APIServices.deleteSaveaddress(id)
        .then((res) => {
          if (res.data.success) {
            snack.success(res.data.message);
            getData();
          }
        })
        .catch((e) => {
          snack.warning(e.data.message);
        })
        .finally((e) => {
          setShowModal(false);
        });
    }
  };

  return (
    <>
      <Modal
        onClose={() => {
          setShowModal(false);
        }}
        open={showModal}
        title={"Confirm delete address"}
      >
        <div className="flex flex-col gap-8 w-full my-2">
          <div className="text-base">Are you sure you want to delete IP ?</div>
          <div className="grid grid-cols-2 gap-2">
            <button
              disabled={loadingDelete}
              onClick={() => {
                handleDelete(selectedId, true);
              }}
              className="rounded-md px-2 h-9 bg-red-500 text-white"
            >
              {loadingDelete ? (
                <AiOutlineLoading className="mx-auto animate-spin" />
              ) : (
                " Yes, delete it"
              )}
            </button>
            <button
              disabled={loadingDelete}
              onClick={() => {
                setShowModal(false);
              }}
              className="link-auth-register !h-9 col-span-1 !w-full"
            >
              No, cancel it
            </button>
          </div>
        </div>
      </Modal>
      <div className="pb-10">
        <div className="h-[1px] mt-20 mb-8 bg-[#9B9DAC] w-full"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-0">
          <div className="col-span-1 px-6">
            <div className="flex gap-1 items-center">
              <AccountBalanceWalletRoundedIcon />
              <span>Save wallet addresses</span>
            </div>
            <div className="mt-3">
              You can save your addresses for save time to rent energy.
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="w-full mt-14"
              autoComplete="off"
            >
              <div className="input-container">
                <input
                  value={values.address}
                  placeholder="address"
                  type={"text"}
                  className={`!pl-4 ${
                    touched.address && errors.address ? "error" : ""
                  }`}
                  onChange={(e) => setFieldValue("address", e.target.value)}
                />
              </div>
              <div className="input-container">
                <input
                  value={values.name}
                  placeholder="name"
                  type={"text"}
                  className={`!pl-4 ${
                    touched.name && errors.name ? "error" : ""
                  }`}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
              </div>
              {isSubmitting ? (
                <span type="submit" className="submit-btn">
                  <AiOutlineLoading className="animate-spin" />
                </span>
              ) : (
                <button type="submit" className="submit-btn">
                  Submit
                </button>
              )}
            </form>
          </div>
          <div className=" col-span-1 px-6">
            <div className="flex gap-1 items-center">
              <AccountBalanceWalletRoundedIcon />
              <span>List Save wallet addresses</span>
            </div>
            {!list.length ? (
              <div className="w-full flex items-center mt-4 flex-col">
                <Nocontent />
                <span>No Wallet saved</span>
              </div>
            ) : (
              list?.map((item, index) => (
                <div className="flex justify-between items-center my-2">
                  <span>
                    {index + 1}. {item.name}
                  </span>
                  <div className="flex gap-1">
                    <span>{addressHandler(item.address)}</span>
                    <Copy
                      fontSize="small"
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(item.address);
                        snack.success("Copied.");
                      }}
                    />
                    <DeleteRoundedIcon
                      onClick={() => {
                        handleDelete(item.id, false);
                      }}
                      className="text-[#ef6a6a] cursor-pointer"
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}
