import axios from "axios";
import { handleSuccess, handleError } from "../utils/axiosHandler";
import {
  authHeader,
  authHeaderFormData,
  authHeaderJson,
  authHeaderJsonDelegate,
  headerJson,
  headerJsonUpload,
} from "../utils/auth-header";

// const base_url = "http://localhost:4030/api/";
// const base_url_tron = "http://localhost:4030/api/";
const base_url = "https://api.transferbyme.com/api/";
const base_url_tron = "https://api.transferbyme.com/api/";
// const base_url_main = "http://localhost:3000/api/";
// const base_url_main_tron = "http://localhost:3000/api/";
const base_url_main = "https://publicapi.transferbyme.com/api/";
const base_url_main_tron = "https://publicapi.transferbyme.com/api/";

const delegateEnergy = (body, apikey) => {
  const url = base_url_main + "transaction/delegate";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJsonDelegate(apikey) });
};

function registerAccount(body) {
  const url = base_url + "user/register";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
}

function verifyRegisterAccount(body) {
  const url = base_url + "user/register/verify";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
}

function uploadAvatar(body) {
  const url = base_url + "user/upload";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJsonUpload() });
}

function loginAccount(body) {
  const url = base_url + "user/login";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
}

const authorizeLogin = (body) => {
  const url = base_url + "user/verifycode";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
};

const resendEmail = (body) => {
  const url = base_url + "user/requestCode";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
};

const resendSMS = (body) => {
  const url = base_url + "user/requestCodeSMS";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
};

const requestForgetPassword = (body) => {
  const url = base_url + "user/forgetPassword";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
};

const handleChanegPasswordLogedinUser = (body) => {
  const url = base_url + "user/forgetPasswordLogedin";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const getdepositsList = (body) => {
  const url = base_url + "user/getdeposits";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
};

const authorizeForgetPassword = (body) => {
  const url = base_url + "user/forgetPassword/verify";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: headerJson() });
};

const updateUserInformations = (body) => {
  const url = base_url + "user/updateUser";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const handleEstimatePriceByDateRange = (body) => {
  const url = base_url + "user/EstimatePriceByDateRange";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const checkenergyBalance = (body) => {
  const url = base_url + "user/energyBalance";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const getAllTransactions = (body) => {
  const url = base_url + "user/getAllRequests";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const getInformationsUser = () => {
  const url = base_url + "user/getInformation";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const handleAddMobileNumber = (body) => {
  const url = base_url + "user/addmobile";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const getAllNotificationList = (body) => {
  const url = base_url + "user/notification";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const UpdateNotification = () => {
  const url = base_url + "user/notification";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.put(url, {}, { headers: authHeaderJson() });
};

const updateMinBalance = (body) => {
  const url = base_url + "user/updateminbalance";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const handleVerfiyMobileNumber = (body) => {
  const url = base_url + "user/verifymobile";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const getAllUserPlansList = () => {
  const url = base_url + "user/plans";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const getAllUserRequestsCount = () => {
  const url = base_url + "user/requests";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const handleEstimatePrice = () => {
  const url = base_url + "user/EstimatePrice";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const handleEstimatePriceByPlan = () => {
  const url = base_url + "user/EstimatePriceByPlan";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const handleEconomicalPrice = () => {
  const url = base_url + "user/economicalPrice";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const handleNotificationSettings = (body) => {
  const url = base_url + "user/notification/settings";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.put(url, body, { headers: authHeaderJson() });
};

// save addresses

const handleGetSaveaddress = () => {
  const url = base_url + "user/saveaddress";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const handleAddNewSaveaddress = (body) => {
  const url = base_url + "user/saveaddress";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const deleteSaveaddress = (id) => {
  const url = base_url + "user/saveaddress/" + id;
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.delete(url, { headers: authHeaderJson() });
};


// wallet create
const createWalletUser = () => {
  const url = base_url + "wallet/createwallet";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

// estimate
const handleEstimateTransferResources = (from_address, to_address) => {
  const url = base_url + "estimate/estimateTransfer";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, {
    headers: authHeaderJson(),
    params: {
      from_address: from_address,
      to_address: to_address,
    },
  });
};

// ip apis
const handleAddNewIP = (body) => {
  const url = base_url + "ip/addNewIp";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

// ip apis
const getRemainRequestCount = (body) => {
  const url = base_url + "user/getRemainRequestCount";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const getAllUserIP = () => {
  const url = base_url + "ip/getAll";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const deleteAnIp = (id) => {
  const url = base_url + "ip/deleteIP/" + id;
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.delete(url, { headers: authHeaderJson() });
};

// api key
const generateApiKeyUser = (body) => {
  const url = base_url + "apikey/generateApiKey";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const updateMainApiKey = (body) => {
  const url = base_url + "apikey/updateMainKey";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const getDetailApiKey = (body) => {
  const url = base_url + "apikey/detail";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

const deleleApiKey = (id) => {
  const url = base_url + "apikey/delete/" + id;
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.delete(url, { headers: authHeaderJson() });
};

const getAllApiKeyUser = () => {
  const url = base_url + "apikey/getAllApiKey";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

// add leasing
const insertNewLeasing = (body) => {
  const url = base_url + "user/insertNewLeasing";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.post(url, body, { headers: authHeaderJson() });
};

// get leasing list
const getAllLeasingUser = () => {
  const url = base_url + "user/leasing";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, { headers: authHeaderJson() });
};

const deleteLeasing = (id) => {
  const url = base_url + "user/leasing/" + id;
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.delete(url, { headers: authHeaderJson() });
};

// edit leasing
const updateLeasingUser = (body) => {
  const url = base_url + "user/leasing";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.put(url, body, { headers: authHeaderJson() });
};

// check balance
const handleCheckBalanceUser = (address) => {
  const url = base_url_tron + "transactions/trx";
  axios.interceptors.response.use(handleSuccess, handleError);
  return axios.get(url, {
    headers: authHeaderJson(),
    params: {
      address: address,
    },
  });
};

export const APIServices = {
  registerAccount,
  loginAccount,
  uploadAvatar,
  handleEstimatePrice,
  handleEconomicalPrice,
  updateLeasingUser,
  handleEstimatePriceByPlan,
  deleteSaveaddress,
  getDetailApiKey,
  authorizeLogin,
  updateMainApiKey,
  deleleApiKey,
  getdepositsList,
  handleCheckBalanceUser,
  handleAddNewSaveaddress,
  handleGetSaveaddress,
  deleteLeasing,
  handleEstimatePriceByDateRange,
  requestForgetPassword,
  authorizeForgetPassword,
  updateUserInformations,
  resendSMS,
  getAllTransactions,
  createWalletUser,
  handleEstimateTransferResources,
  getRemainRequestCount,
  resendEmail,
  handleChanegPasswordLogedinUser,
  handleAddNewIP,
  getAllUserIP,
  deleteAnIp,
  generateApiKeyUser,
  getAllApiKeyUser,
  checkenergyBalance,
  delegateEnergy,
  getInformationsUser,
  handleAddMobileNumber,
  updateMinBalance,
  getAllNotificationList,
  handleVerfiyMobileNumber,
  UpdateNotification,
  insertNewLeasing,
  handleNotificationSettings,
  verifyRegisterAccount,
  getAllUserPlansList,
  getAllUserRequestsCount,
  getAllLeasingUser,
};
