import React, { useState, useEffect } from "react";
import { AiOutlineLoading } from "react-icons/ai";

export default function ResendCountDown(props) {
  const [seconds, setSeconds] = useState(props.time ?? 60);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    clockStart(props.time ?? 60);
  }, [props.retryCount]);

  const clockStart = (timeleft) => {
    setDisabled(true);
    var time = setInterval(function () {
      setSeconds(timeleft);
      timeleft -= 1;
      if (timeleft < 0) {
        clearInterval(time);
        // props.onFinish();
      }
    }, 1000);
  };

  useEffect(() => {
    if (seconds == 0) {
      setDisabled(false);
    }
  }, [seconds]);

  return (
    <div
      onClick={() => {
        if (!disabled) {
          props.handleClick();
        }
      }}
      className={`text-base select-none flex gap-1 ${
        disabled
          ? "text-[#D9D9D9] cursor-not-allowed"
          : "text-[#2083FF] cursor-pointer"
      }`}
    >
      {props.loading ? (
        <AiOutlineLoading className="animate-spin text-[#2083FF]" />
      ) : (
        !props.time &&
        <span>Resend</span>
      )}
      {disabled && (
        <span className={`inline-block min-w-10`}>{`${
          seconds > 0 ? seconds : ""
        }`}</span>
      )}
    </div>
  );
}
