import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/mainLayout";
import { AiOutlineLoading } from "react-icons/ai";
import { APIServices } from "../../api";
import snack from "../../utils/snack";
import ResendCountDown from "../../utils/ResendCountDown";
import Header from "../../components/shared/header";
import { Link } from "react-router-dom";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { commafy2 } from "../../utils/commafy";
export default function EstimatePage() {
  const [data, setData] = useState({});
  const [amount, setAmount] = useState(null);
  const [duration, setDuration] = useState("1d");
  const [loading, setLoading] = useState(false);
  const [costOfRequest, setCostOfRequest] = useState({});
  const [showData, setShowData] = useState(false);
  const amountPackOfEnergy = [10, 20, 50, 100, 200, 500, 1000];
  const [minimumSave, setMinimumSave] = useState(50);
  const [economiData, setEconomiData] = useState({});
  const [durationList, setDurationList] = useState([
    "1d",
    "3d",
    "7d",
    "14d",
    "30d",
  ]);

  const getData = () => {
    setShowData(false);
    if (amount < 65000) {
      snack.warning("Energy amount should be more than 65000.");
    } else if (minimumSave < 0 || minimumSave > 100) {
      snack.warning("MinimumSave should be in range of 0 to 100.");
    } else {
      setLoading(true);
      APIServices.handleEstimatePriceByDateRange({
        amount: amount,
        duration: duration,
        save: minimumSave,
      })
        .then((res) => {
          setCostOfRequest(res.data);
          setShowData(true);
        })
        .finally((e) => {
          setLoading(false);
        });
    }
  };

  const geteconomicalPrice = () => {
    APIServices.handleEconomicalPrice().then((res) => {
      setEconomiData(res.data.data);
    });
  };

  useEffect(() => {
    geteconomicalPrice();
  }, []);

  useEffect(() => {
    if (showData) {
      setTimeout(() => {
        setShowData(false);
      }, 30000);
    }
  }, [showData]);

  return (
    <MainLayout className="">
      {/* <Header /> */}
      <div className="p-4">
        <div className="p-8 rounded-[20px] bg-white shadow-sm my-4">
          <div className="font-semibold mb-4">
            Bulk service - second service
          </div>
          <div>Estimate rent energy for a range time - bulk service</div>
          <div className="grid grid-cols-12 gap-y-3">
            <div className="col-span-12 md:col-span-4">
              <div className="input-container">
                <label htmlFor="amount" className="cursor-pointer !font-normal">
                  Amount
                </label>
                <input
                  className="md:!w-1/2 !pl-4"
                  id="amount"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <div className="flex gap-1 mt-1 overflow-x-auto pb-1">
                  {amountPackOfEnergy?.map((item, index) => (
                    <span
                      className={`mr-1 p-1 text-xs flex gap-0.5 cursor-pointer items-center rounded-md ${
                        amount / 65000 == item ? "bg-[#00E9D0]" : "bg-[#eaeaea]"
                      }`}
                      onClick={() => {
                        setAmount(item * 65000);
                      }}
                    >
                      <span>X</span>
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 items-center flex">
              {durationList.map((item, index) => (
                <span
                  className={`mx-1 relative md:top-3 text-lg cursor-pointer ${
                    duration == item ? "text-black" : "text-gray-400"
                  }`}
                  onClick={() => {
                    setDuration(item);
                  }}
                >
                  {item}
                </span>
              ))}
            </div>
            <div className="col-span-12 md:col-span-5 flex items-center gap-2">
              <div className="input-container">
                <label htmlFor="saved" className="cursor-pointer !font-normal">
                  Minimum save
                </label>
                <input
                  className="!pl-4"
                  id="saved"
                  placeholder="Minimum save per transaction"
                  value={minimumSave}
                  onChange={(e) => {
                    setMinimumSave(e.target.value);
                  }}
                />
              </div>
              <div className="top-3.5 relative flex gap-2 items-center justify-between">
                {loading ? (
                  <span type="submit" className="link-auth-register">
                    <AiOutlineLoading className="animate-spin" />
                  </span>
                ) : (
                  <button className="link-auth-register" onClick={getData}>
                    Calculate
                  </button>
                )}
              </div>
            </div>
            <div className="col-span-12 mb-5">
              <div className="flex gap-2 items-end ml-20 text-[#2083FF]">
                <Link className="" to={"/delegate"}>
                  Delegate
                </Link>
                <ArrowForwardRoundedIcon />
              </div>
            </div>
          </div>
          {showData &&
            (costOfRequest?.complete ? (
              <div className="flex justify-around gap-6 flex-wrap py-2 items-center mt-4 bg-black bg-opacity-65 text-white min-h-12 rounded-md">
                <span>
                  All cost: {commafy2(costOfRequest?.cost.toFixed(2))}
                </span>
                <span>
                  average cost:
                  {costOfRequest?.avg_transaction_price.toFixed(2)}
                </span>
                <span>
                  average save per transaction: {costOfRequest?.saved} %
                </span>
                <span className="flex items-center gap-1">
                  <span>Availabe for </span>
                  <ResendCountDown
                    retryCount={showData}
                    time={30}
                    handleClick={() => {}}
                    loading={false}
                  />
                  <span className="-ml-6">s</span>
                </span>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="bg-[#ef6a6a] p-2 rounded-md mt-5 md:mt-0">
                  This amount is not availabe now, we calculate how much we have
                  at {costOfRequest?.save}% cheaper fee, so according to
                  availabe energy amount make transaction.
                </div>
                <div className="bg-black flex justify-around gap-6 flex-wrap py-2 items-center mt-4 bg-opacity-65 text-white min-h-12 rounded-md">
                  <span>Availabe: {commafy2(costOfRequest?.helperAmount)}</span>
                  <span>Transaction count: {costOfRequest?.count}</span>
                  <span>
                    All cost: {commafy2(costOfRequest?.cost.toFixed(2))}
                  </span>
                  <span>
                    averag cost:{" "}
                    {costOfRequest?.avg_transaction_price.toFixed(2)}
                  </span>
                  <span>save per transaction: {costOfRequest?.saved} %</span>
                  <span className="flex items-center gap-1">
                    <span>Availabe for </span>
                    <ResendCountDown
                      retryCount={showData}
                      time={30}
                      handleClick={() => {}}
                      loading={false}
                    />
                    <span className="-ml-6">s</span>
                  </span>
                </div>
              </div>
            ))}
        </div>
        <div className="p-8 rounded-[20px] bg-white shadow-sm my-4">
          <div className="font-semibold mb-4">
            Bulk service - second service
          </div>
          <div className="mb-4">
            Economical transaction - Estimate best and cheapest duration - How
            much we have at 50% cheaper fee
          </div>
          <div className="my-4 overflow-x-auto">
            <div>Daily Requests</div>
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="px-3 whitespace-nowrap">Count of availabe</th>
                  <th className="px-3 whitespace-nowrap">
                    save per transaction
                  </th>
                  <th className="px-3 whitespace-nowrap">
                    cost per transaction
                  </th>
                </tr>
              </thead>
              <tbody>
                {economiData?.daily?.map((item, index) => (
                  <tr className="h-10">
                    <td className="text-center">{item.amount}</td>
                    <td className="text-center">{item.saved.toFixed(2)} %</td>
                    <td className="text-center">{item.cost.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="my-4">Sum : {economiData?.total_daily_amount}</div>
          </div>
          <hr />
          <div className="my-4 overflow-x-auto">
            <div>3 Daily Requests</div>
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="px-3 whitespace-nowrap">Count of availabe</th>
                  <th className="px-3 whitespace-nowrap">
                    save per transaction
                  </th>
                  <th className="px-3 whitespace-nowrap">
                    cost per transaction
                  </th>
                </tr>
              </thead>
              <tbody>
                {economiData?._3daily?.map((item, index) => (
                  <tr className="h-10">
                    <td className="text-center">{item.amount}</td>
                    <td className="text-center">{item.saved.toFixed(2)} %</td>
                    <td className="text-center">{item.cost.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="my-4">Sum : {economiData?.total_3daily_amount}</div>
          </div>
          <hr />
          <div className="my-4 overflow-x-auto">
            <div>7 Daily Requests</div>
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="px-3 whitespace-nowrap">Count of availabe</th>
                  <th className="px-3 whitespace-nowrap">
                    save per transaction
                  </th>
                  <th className="px-3 whitespace-nowrap">
                    cost per transaction
                  </th>
                </tr>
              </thead>
              <tbody>
                {economiData?._7daily?.map((item, index) => (
                  <tr className="h-10">
                    <td className="text-center">{item.amount}</td>
                    <td className="text-center">{item.saved.toFixed(2)} %</td>
                    <td className="text-center">{item.cost.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="my-4">Sum : {economiData?.total_7daily_amount}</div>
          </div>
          <hr />
          <div className="my-4 overflow-x-auto">
            <div>14 Daily Requests</div>
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="px-3 whitespace-nowrap">Count of availabe</th>
                  <th className="px-3 whitespace-nowrap">
                    save per transaction
                  </th>
                  <th className="px-3 whitespace-nowrap">
                    cost per transaction
                  </th>
                </tr>
              </thead>
              <tbody>
                {economiData?._14daily?.map((item, index) => (
                  <tr className="h-10">
                    <td className="text-center">{item.amount}</td>
                    <td className="text-center">{item.saved.toFixed(2)} %</td>
                    <td className="text-center">{item.cost.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="my-4">
              Sum : {economiData?.total_14daily_amount}
            </div>
          </div>
          <hr />
          <div className="my-4 overflow-x-auto">
            <div>Monthly Requests</div>
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="px-3 whitespace-nowrap">Count of availabe</th>
                  <th className="px-3 whitespace-nowrap">
                    save per transaction
                  </th>
                  <th className="px-3 whitespace-nowrap">
                    cost per transaction
                  </th>
                </tr>
              </thead>
              <tbody>
                {economiData?._30daily?.map((item, index) => (
                  <tr className="h-10">
                    <td className="text-center">{item.amount}</td>
                    <td className="text-center">{item.saved.toFixed(2)} %</td>
                    <td className="text-center">{item.cost.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="my-4">
              Sum : {economiData?.total_30daily_amount}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
