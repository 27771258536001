import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  keyPrefix: '',
  storage,
  blacklist: ['sw', 'sidebar']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleWares = [thunk]

let composer
if (process.env.NODE_ENV === 'development') {
  /* eslint-disable global-require */
  const { composeWithDevTools } = require('@redux-devtools/core')
  /* eslint-enable global-require */

  composer = composeWithDevTools
} else {
  composer = compose
}

const store = createStore(persistedReducer, compose(applyMiddleware(...middleWares)))

const persistor = persistStore(store)

export { store, persistor }
