import React, { useEffect, useState } from "react";
import { APIServices } from "../../api";
import MainLayout from "../../components/layout/mainLayout";
import moment from "jalali-moment";
import moment2 from "moment";
import PaginationComponent from "../../components/shared/pagination";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactComponent as Nocontent } from "../../components/assets/nocontent.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AiOutlineLoading } from "react-icons/ai";
import { addressHandler } from "../../utils/addressHandler";
import snack from "../../utils/snack";
import { ReactComponent as Copy } from "../../components/assets/copy.svg";

export default function TransactionsPage() {
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState(null);

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [timeRange, setTimeRange] = useState({
    from: "",
    to: "",
  });

  const getData = (page) => {
    setLoadingFilter(true);
    APIServices.getAllTransactions({
      page: page,
      pageSize: 10,
      ...(status != null && { status: status }),
      ...(timeRange.from && { from: timeRange.from }),
      ...(timeRange.to && { to: timeRange.to }),
    })
      .then((res) => {
        // setList(res.data.data);
        // setAllAmount(res.data.allAmount)
        setSelectedData(res.data.data);
        setCount(Math.ceil(res.data.count / 10));
      })
      .finally((e) => {
        setLoadingFilter(false);
      });
  };

  useEffect(() => {
    getData(1);
  }, []);

  const handleShcnageType = (e) => {
    setStatus(e.target.value);
  };

  const handleChangeDateRange = (e, what) => {
    setTimeRange({
      ...timeRange,
      [what]: moment2(e.$d).format("YYYY-MM-DD"),
    });
  };

  return (
    <MainLayout>
      <div className="text-base mb-2 ml-1"> Transactions</div>
      <div className="table-container overflow-x-auto card">
        <div className="my-4 flex flex-wrap gap-4">
          <div className="flex gap-1 min-w-44 whitespace-nowrap items-center">
            <label className="text-sm">Type:</label>
            <select
              className="h-10 w-full border rounded-md text-sm"
              onChange={(e) => {
                handleShcnageType(e);
              }}
              defaultValue={status}
            >
              <option value={null} selected></option>
              <option value={"0"}>Pending</option>
              <option value={"1"}>Succeed</option>
              <option value={"2"}>Failed</option>
            </select>
          </div>
          <div className="flex gap-1 min-w-44 whitespace-nowrap items-center">
            <label className="text-sm">From:</label>
            <label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  className="datetimepicker"
                  // maxDate={timeRange.to ?? null}
                  format="YYYY-MM-DD"
                  onChange={(e) => handleChangeDateRange(e, "from")}
                />
              </LocalizationProvider>
            </label>
          </div>
          <div className="flex gap-1 min-w-44 whitespace-nowrap items-center">
            <label className="text-sm">To:</label>
            <label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  format="YYYY-MM-DD"
                  // minDate={timeRange.from ?? null}
                  className="datetimepicker"
                  onChange={(e) => handleChangeDateRange(e, "to")}
                />
              </LocalizationProvider>
            </label>
          </div>
          {loadingFilter ? (
            <button className="h-10 w-[110px] text-center text-white bg-black rounded-md flex justify-center items-center">
              <AiOutlineLoading className="animate-spin" />
            </button>
          ) : (
            <button
              onClick={() => {
                getData(page);
              }}
              className="h-10 w-[110px] text-center text-white bg-black rounded-md"
            >
              Filter
            </button>
          )}
        </div>
        <table>
          <thead className="h-10 bg-gray-200 rounded-sm">
            <tr className="h-full">
              <th className="min-h-20 px-3">#</th>
              <th className="min-h-20 px-3">Time</th>
              {/* <th className="min-h-20 px-3">To Address</th> */}
              <th className="min-h-20 px-3">Api key</th>
              <th className="min-h-20 px-3 whitespace-nowrap">Fee</th>
              <th className="min-h-20 px-3 whitespace-nowrap">
                Without TrannsferByMe
              </th>
              {/* <th className="min-h-20 px-3">TXID</th> */}
              <th className="min-h-20 px-3">Saved</th>
              <th className="min-h-20 px-3">Receive</th>
              <th className="min-h-20 px-3">Status</th>
            </tr>
          </thead>
          <tbody>
            {selectedData?.map((item, index) => (
              <tr className="h-10" key={item?.id}>
                <td className="text-center text-sm px-2 whitespace-nowrap">
                  {(page - 1) * 10 + index + 1}
                </td>
                <td className="text-center text-sm px-2 whitespace-nowrap">
                  {moment(item?.createddate).format("jYYYY/jMM/jDD HH:mm:ss")}
                </td>
                {/* <td className="text-center text-sm px-2 whitespace-nowrap">
                  <div className="flex gap-1">
                    <span>{addressHandler(item.destination)}</span>
                    <Copy
                      fontSize="small"
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(item.destination);
                        snack.success("Copied.");
                      }}
                    />
                  </div>
                </td> */}
                <td className="text-center text-sm px-2 whitespace-nowrap">
                  <div className="flex gap-1 justify-center">
                    <span>
                      {item.apikey ? addressHandler(item.apikey) : "-"}
                    </span>
                    <Copy
                      fontSize="small"
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(item.apikey);
                        snack.success("Copied.");
                      }}
                    />
                  </div>
                </td>
                <td className="text-center text-sm px-2 whitespace-nowrap">
                  {item.fee} TRX
                </td>
                <td className="text-center text-sm px-2 whitespace-nowrap">
                  {item?.tron_fee ? item.tron_fee + " TRX" : "-"}
                </td>
                <td className="text-center text-sm px-2 whitespace-nowrap">
                  {item.saved ? item.saved.toFixed(0) + " %" : "-"}
                </td>
                {/* <td className="text-center text-sm px-2 whitespace-nowrap">
                  <div className="flex gap-1 justify-center">
                    <span>
                      {item.txid ? (
                        <Link
                          className="hover:underline"
                          target="_blank"
                          to={`https://tronscan.org/#/transaction/${item.txid}`}
                        >
                          {addressHandler(item.txid)}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </span>
                    {item.txid && (
                      <Copy
                        fontSize="small"
                        className="cursor-pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(item.txid);
                          snack.success("Copied.");
                        }}
                      />
                    )}
                  </div>
                </td> */}
                <td className="text-center text-sm px-2 whitespace-nowrap">
                  <div className="flex gap-1 justify-center">
                    <span>
                      {item.receive ? addressHandler(item.receive) : "-"}
                    </span>
                    {item.receive && (
                      <Copy
                        fontSize="small"
                        className="cursor-pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(item.receive);
                          snack.success("Copied.");
                        }}
                      />
                    )}
                  </div>
                </td>
                <td className="text-center text-sm px-2 whitespace-nowrap">
                  {item.status == "0" && "Pending"}
                  {item.status == "1" && "Succeed"}
                  {item.status == "2" && "Failed"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!selectedData.length && (
          <div className="flex justify-center items-center min-h-[420px] flex-col">
            <Nocontent />
            <div className="text-center">No data found!</div>
          </div>
        )}
      </div>
      <div className="mt-6">
        {count > 1 && (
          <PaginationComponent
            count={count}
            page={page}
            onChange={(e) => {
              setPage(e);
              getData(e);
            }}
          />
        )}
      </div>

      <div className="mt-4 gap-4 mb-10 flex flex-col">
        {/* <div className=" flex gap-2">
          <span>All Amount Transfered : </span>
          <span>{commafy2(hanlderFloatNumber(allAmount, 2))}</span>
        </div> */}
        {/* <div className=" flex gap-2">
          <span>Fee : </span>
          <span>{selectedData?.fee}</span>
        </div> */}
      </div>
    </MainLayout>
  );
}
