import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import ReactInputVerificationCode from "react-input-verification-code";
import * as Yup from "yup";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { APIServices } from "../../api";
import ResendCountDown from "../../utils/ResendCountDown";

export default function RegisterStep2(props) {
  const [confirmCode, setConfirmCode] = useState("");
  const [retryCount, setRetryCount] = useState(1);
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const initialValues = {
    otpcode: "",
  };

  const ValidationSchema = Yup.object().shape({
    otpcode: Yup.string().min(6, "").required(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitForm(values, setSubmitting);
    },
  });

  const {
    errors,
    setFieldValue,
  } = formik;

  const handleSubmitForm = (values) => {
    props.handleConfirmAccount({
      code: confirmCode,
    });
  };

  useEffect(() => {
    setUserEmail(props.email);
  }, [props.email]);

  const handleResendEmail = () => {
    setLoadingSendCode(true);
    APIServices.resendEmail({ email: props.email }).then((res) => {
      if (res.data.success) {
        setLoadingSendCode(false);
        setRetryCount((prev) => prev + 1);
      }
    });
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full mt-14"
      autoComplete="off"
    >
      <div
        className={`verification-code-styles verification-code !text-white flex flex-col w-[260px] -mt-4 ${
          errors.otpcode ? "has-error" : ""
        }`}
      >
        <div className="flex text-[17px] text-black text-justify gap-5 items-center">
          Enter the 6-digits verification code sent to
        </div>
        <div className="text-black font-medium text-lg mb-3">
          {userEmail ?? ""}
        </div>
        <ReactInputVerificationCode
          length={6}
          autoFocus
          onChange={(val) => {
            setConfirmCode(val);
            setFieldValue("otpcode", val);
          }}
          passwordMask="-"
          value={confirmCode}
          placeholder=""
          onCompleted={(e) => {
            // handleConfirmAccount(e);
          }}
        />
        <div className="flex mt-2 justify-center">
          <ResendCountDown
            retryCount={retryCount}
            handleClick={handleResendEmail}
            loading={loadingSendCode}
          />
        </div>
        {props.loadingConfirm ? (
          <span type="submit" className="submit-btn">
            <AiOutlineLoading className="animate-spin" />
          </span>
        ) : (
          <button
            // onClick={() => handleConfirmAccount(confirmCode)}
            type="submit"
            className="submit-btn"
          >
            Submit
          </button>
        )}
      </div>
      <div
        onClick={() => {
          props.goBack();
        }}
        className="flex cursor-pointer text-[#2083FF] justify-center items-center gap-0.5"
      >
        <ArrowBackIosNewRoundedIcon fontSize="small" />
        <span>Back</span>
      </div>
    </form>
  );
}
