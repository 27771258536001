import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import * as Yup from "yup";
import { APIServices } from "../../api";
import MainLayout from "../../components/layout/mainLayout";
import Modal from "../../components/shared/modal";
import { addressHandler } from "../../utils/addressHandler";
import snack from "../../utils/snack";
import { ReactComponent as Copy } from "../../components/assets/copy.svg";

export default function SettingsPage() {
  const [leasingInfo, setLeasingInfo] = useState([]);
  const [startLeasing, setStartLeasing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const initialValues = {
    receive_address: "",
    remainresource: 0,
  };

  const ValidationSchema = Yup.object().shape({
    receive_address: Yup.string().required(),
    remainresource: Yup.number().required(),
  });

  // resource_value
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleInsertLeasing(values, setSubmitting, resetForm);
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
    validateForm,
    setSubmitting,
  } = formik;

  const handleInsertLeasing = (values, setSubmitting, resetForm) => {
    setSubmitting(true);
    let temp = {
      address: values.receive_address,
      remainresource: Number(values.remainresource),
    };
    if (temp.remainresource > 0) {
      if (editing) {
        APIServices.updateLeasingUser(JSON.stringify(temp))
          .then((res) => {
            console.log(res.data);
            setStartLeasing(false);
            resetForm();
            setEditing(false);
            if (res.data.success) {
              snack.success(res.data.message);
            }
            getData();
          })
          .finally((e) => {
            setSubmitting(false);
          });
      } else {
        APIServices.insertNewLeasing(JSON.stringify(temp))
          .then((res) => {
            snack.success(res.data.message);
            getData();
            resetForm();
            setStartLeasing(false);
            setEditing(false);
          })
          .finally((e) => {
            setSubmitting(false);
          });
      }
    } else {
      snack.warning("Please enter valid intger number.");
      setSubmitting(false)
    }
  };

  const getData = () => {
    APIServices.getAllLeasingUser().then((res) => {
      setLeasingInfo(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const handleUpdate = () => {
    setStartLeasing(true);
    setEditing(true);
    setFieldValue("receive_address", leasingInfo[0].address);
    setFieldValue("remainresource", leasingInfo[0].remainresource);
  };

  const confirmDelete = () => {
    setDeleting(true);
    APIServices.deleteLeasing(leasingInfo[0]?.address)
      .then((res) => {
        setDeleteModal(false);
        if (res.data.success) {
          snack.success(res.data.message);
        }
        getData();
      })
      .finally((e) => {
        setDeleting(false);
      });
  };

  return (
    <MainLayout>
      <Modal
        onClose={() => {
          setDeleteModal(false);
        }}
        open={deleteModal}
        title={"Confirm delete leasing"}
      >
        <div className="flex flex-col gap-3">
          <div className="font-semibold text-lg">
            Are you sure you want to delete?
          </div>
          <div className="grid grid-cols-2 gap-2 mt-4">
            <button
              className={`bg-[#ef6a6a] text-white h-10 rounded-md text-center ${
                deleting ? "opacity-60 cursor-not-allowed" : ""
              }`}
              onClick={confirmDelete}
            >
              Delete
            </button>
            <button
              className={`bg-[#3385ff] text-white h-10 rounded-md text-center ${
                deleting ? "opacity-60 cursor-not-allowed" : ""
              }`}
              onClick={() => {
                setDeleteModal(false);
                setDeleting(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className="text-base mb-2 ml-1">Settings</div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 min-h-[340px]">
        <div className="col-span-1 bg-white shadow-sm card">
          {leasingInfo[0]?.address ? (
            <div className="flex flex-col gap-2">
              <div className="text-lg mb-2 font-semibold">Your Leasing</div>
              <div className="flex items-center justify-between">
                <span>Address</span>
                <div className="flex gap-2 items-center">
                  <Copy
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(leasingInfo[0]?.address);
                      snack.success("Copied.");
                    }}
                  />
                  <span>{addressHandler(leasingInfo[0]?.address)}</span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <span>count</span>
                <span>{leasingInfo[0]?.remainresource}</span>
              </div>

              <div className="grid grid-cols-2 gap-2 mt-10">
                <button
                  className="bg-[#ef6a6a] text-white h-10 rounded-md text-center"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  className="bg-[#3385ff] text-white h-10 rounded-md text-center"
                  onClick={handleUpdate}
                >
                  Edit
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center flex-col gap-5 items-center h-full">
              <div>You don't have any auto leasing!</div>

              <button
                className="bg-[#3385ff] text-white h-10 rounded-md px-6 text-center"
                onClick={() => {
                  setStartLeasing(true);
                }}
              >
                Create One
              </button>
            </div>
          )}
        </div>
        <div
          className={`col-span-1 card bg-white shadow-sm transition-all ${
            startLeasing ? "opacity-100" : "opacity-0"
          }`}
        >
          <form
            onSubmit={formik.handleSubmit}
            className="w-full mt-14"
            autoComplete="off"
          >
            <div className="input-container">
              <label>Address</label>
              <input
                value={values.receive_address}
                type="text"
                placeholder="address ... "
                disabled={editing}
                className={`custom ${
                  touched.receive_address && errors.receive_address
                    ? "error"
                    : ""
                }`}
                onChange={(e) => {
                  !editing && setFieldValue("receive_address", e.target.value);
                }}
              />
            </div>
            <div className="input-container">
              <label>Count</label>
              <input
                value={values.remainresource}
                type="number"
                placeholder="address ... "
                className={`custom ${
                  touched.remainresource && errors.remainresource ? "error" : ""
                }`}
                onChange={(e) => {
                  setFieldValue("remainresource", e.target.value);
                }}
              />
            </div>
            {/* <div className="flex justify-center"> */}
            {isSubmitting ? (
              <span type="submit" className="submit-btn">
                <AiOutlineLoading className="animate-spin" />
              </span>
            ) : (
              <button
                className="submit-btn"
                type="submit"
                // onClick={() => {
                //   handleInsertLeasing();
                // }}
              >
                Submit
              </button>
            )}
            {/* </div> */}
          </form>
        </div>
      </div>
    </MainLayout>
  );
}
