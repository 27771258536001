import { combineReducers } from 'redux'
import assetReducer from './asset'

import userReducer from './user'
// import { guildReducer } from './datas'
// import { categoryReducer } from './datas'
// import { memberReducer } from './datas'
// import { provinceReducer } from './datas'
// import { unionReducer } from './datas'
// import { userListReducer } from './datas'
const rootReducer = combineReducers({
  user: userReducer,
  asset: assetReducer,
})

export default rootReducer
