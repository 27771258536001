import { useFormik } from "formik";
import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import ReactInputVerificationCode from "react-input-verification-code";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { APIServices } from "../api";
import Email from "../components/assets/email.svg";
import Hide from "../components/assets/hide.svg";
import Logo from "../components/assets/logo.svg";
import Password from "../components/assets/password.svg";
import Show from "../components/assets/show.svg";
import Header from "../components/shared/header";
import { setUserData } from "../components/store/actions/user";
import snack from "../utils/snack";
import ResendCountDown from "../utils/ResendCountDown";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ReCAPTCHA from "react-google-recaptcha";
export default function Login() {
  const [step, setStep] = useState(1);
  const [confirmCode, setConfirmCode] = useState("");
  const [userId, setuserId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [retryCount, setRetryCount] = useState(1);
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
    password: "",
    captcha: ""
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email().required("email"),
    password: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()-_+=/\|?.><])(?=\S+$).{8,}$/,
        {
          message: "",
          excludeEmptyString: false,
        }
      )
      .min(8, "")
      .required()
      .trim(),
    captcha: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitForm(values, setSubmitting);
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
    validateForm,
    setSubmitting,
  } = formik;

  const handleSubmitForm = (values, setSubmitting) => {
    let dto = {
      ...values,
      email: values.email.toLowerCase()
    }
    APIServices.loginAccount(JSON.stringify(dto))
      .then((res) => {
        snack.success(res.data.message);
        setuserId(res.data.data.id);
        setStep(2);
      })
      .finally((e) => {
        setSubmitting(false);
      });
  };

  const handleConfirmAccount = (e) => {
    setLoadingConfirm(true);
    let dto = {
      id: userId,
      otpcode: e,
    };
    APIServices.authorizeLogin(JSON.stringify(dto))
      .then((res) => {
        snack.success("You logged in successfully");
        localStorage.setItem("token", res.data.accessToken);
        dispatch(setUserData(res.data.user));
        navigate("/dashboard");
      })
      .catch((err) => {
        // snack.error("Something went wrong.");
      })
      .finally((e) => {
        setLoadingConfirm(false);
      });
  };

  const onChangeCaptcha = (e) => {
    setFieldValue("captcha", e);
  };

  const handleResendEmail = () => {
    setLoadingSendCode(true);
    APIServices.resendEmail({ email: values.email.toLowerCase() }).then((res) => {
      if (res.data.success) {
        setLoadingSendCode(false);
        setRetryCount((prev) => prev + 1);
      }
    });
  };

  return (
    <div className="auth-bg">
      <Header />
      <div className="auth-wrapper">
        <div className="flex gap-1 flex-wrap items-center text-[40px] tracking-wider">
          <span className="font-extralight whitespace-nowrap">Welcome to</span>
          <p className="text-[#111111] text-[40px] font-bold whitespace-nowrap">
            TransferByMe
          </p>
        </div>
        <br />
        <div className={`${step == 1 ? "box-auth" : "box-auth2"}`}>
          <img className="mx-auto mb-12" src={Logo} />
          {step == 1 ? (
            <form
              onSubmit={formik.handleSubmit}
              className="w-full mt-14"
              autoComplete="off"
            >
              <div className="input-container">
                <input
                  value={values.email}
                  autoFocus
                  type="email"
                  placeholder="Email"
                  className={`${touched.email && errors.email ? "error" : ""}`}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />
                <img src={Email} className="icon" />
              </div>
              <div className="input-container">
                <input
                  value={values.password}
                  placeholder="Password"
                  type={!showPassword ? "password" : "text"}
                  className={`${
                    touched.password && errors.password ? "error" : ""
                  }`}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                />
                <img src={Password} className="icon" />
                {showPassword ? (
                  <img
                    src={Hide}
                    className="icon-2"
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <img
                    src={Show}
                    className="icon-2"
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
              <div className="mb-8 flex captchaContainer justify-center flex-col items-center">
                <ReCAPTCHA
                  sitekey="6LdHhtwpAAAAADou_7XeQbOSoSzNyvnVI8jgFAbQ"
                  onChange={onChangeCaptcha}
                  />
                  {
                    errors.captcha && <div className="text-[#ef6a6a] text-sm">Captcha is required.</div>
                  }
              </div>
              <div className="flex justify-end mb-">
                <Link
                  to="/forgetpassword"
                  className="link-auth -mt-4 underline"
                >
                  Forget password ?
                </Link>
              </div>
              {isSubmitting ? (
                <span type="submit" className="submit-btn">
                  <AiOutlineLoading className="animate-spin" />
                </span>
              ) : (
                <button type="submit" className="submit-btn">
                  Log in
                </button>
              )}
              <div className="flex gap-5 items-center flex-wrap mt-6 justify-between">
                <span className="text-[#111111] text-base whitespace-nowrap">
                  Don't have an account ?
                </span>
                <Link
                  to="/register"
                  className="link-auth whitespace-nowrap underline"
                >
                  Sign up now
                </Link>
              </div>
            </form>
          ) : (
            <>
              <div className="text-base text-black mb-6">
                <span className="text-justify">
                  Enter 6-digits verification code sent to
                </span>
                <br />
                <span className="font-semibold">{values.email}</span>
              </div>
              <div className="verification-code-styles verification-code !text-white flex flex-col w-[260px] -mt-4">
                <ReactInputVerificationCode
                  length={6}
                  autoFocus
                  onChange={(val) => setConfirmCode(val)}
                  passwordMask="-"
                  value={confirmCode}
                  placeholder=""
                  onCompleted={(e) => {
                    handleConfirmAccount(e);
                  }}
                />
                <div className="flex mt-2 justify-center">
                  <ResendCountDown
                    retryCount={retryCount}
                    handleClick={handleResendEmail}
                    loading={loadingSendCode}
                  />
                </div>
                {loadingConfirm ? (
                  <span type="submit" className="submit-btn">
                    <AiOutlineLoading className="animate-spin" />
                  </span>
                ) : (
                  <button
                    onClick={() => handleConfirmAccount(confirmCode)}
                    className="submit-btn"
                  >
                    Continue
                  </button>
                )}
              </div>
              <div
                onClick={() => {
                  setStep(1);
                }}
                className="flex cursor-pointer text-[#2083FF] justify-center items-center gap-0.5"
              >
                <ArrowBackIosNewRoundedIcon fontSize="small" />
                <span>Back</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
