// Types
export const CLEAR_USER = 'CLEAR_USER'
export const SET_TOKEN = 'SET_TOKEN'

// Creators
export const clearUser = () => ({
  type: CLEAR_USER
})

export const setUserData = (payload) => ({
  type: SET_TOKEN,
  payload
})
