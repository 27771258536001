import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/mainLayout";
import { APIServices } from "../../api";
import moment from "jalali-moment";
import moment2 from "moment";
import PaginationComponent from "../../components/shared/pagination";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AiOutlineLoading } from "react-icons/ai";
import { ReactComponent as Nocontent } from "../../components/assets/nocontent.svg";

export default function NotificationsPage() {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [pageNotifs, setPageNotifs] = useState(1);
  const [type, setType] = useState(null);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [timeRange, setTimeRange] = useState({
    from: "",
    to: "",
  });

  const getData = (e) => {
    setLoadingFilter(true);
    APIServices.getAllNotificationList({
      page: e,
      pageSize: 10,
      ...(type != null && type != "" && { type: type }),
      ...(timeRange.from && { from: timeRange.from }),
      ...(timeRange.to && { to: timeRange.to }),
    })
      .then((res) => {
        setList(res.data.data);
        setCount(Math.ceil(res.data.count / 10));
      })
      .finally((e) => {
        setLoadingFilter(false);
      });
  };

  useEffect(() => {
    getData(1);
  }, []);

  const handleShcnageType = (e) => {
    setType(e.target.value);
  };

  const handleChangeDateRange = (e, what) => {
    console.log(moment2(e.$d).format("YYYY-MM-DD"));
    setTimeRange({
      ...timeRange,
      [what]: moment2(e.$d).format("YYYY-MM-DD"),
    });
  };

  return (
    <MainLayout>
      <div className="text-2xl mb-2 ml-1">Notifications</div>
      <div className="bg-white shadow-sm card !p-8">
        <div className="my-4 flex flex-wrap gap-4">
          <div className="flex gap-1 min-w-44 whitespace-nowrap items-center">
            <label className="text-sm">Type:</label>
            <select
              className="h-10 w-full border rounded-md text-sm"
              onChange={(e) => {
                handleShcnageType(e);
              }}
              defaultValue={type}
            >
              <option value={null} selected></option>
              <option value={"0"}>Deposit</option>
              <option value={"1"}>Transaction</option>
              <option value={"2"}>Low Balance</option>
              <option value={"3"}>System</option>
              <option value={"4"}>Update Plane</option>
            </select>
          </div>
          <div className="flex gap-1 min-w-44 whitespace-nowrap items-center">
            <label className="text-sm">From:</label>
            <label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  className="datetimepicker"
                  // maxDate={timeRange.to ?? null}
                  format="YYYY-MM-DD"
                  onChange={(e) => handleChangeDateRange(e, "from")}
                />
              </LocalizationProvider>
            </label>
          </div>
          <div className="flex gap-1 min-w-44 whitespace-nowrap items-center">
            <label className="text-sm">To:</label>
            <label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  format="YYYY-MM-DD"
                  // minDate={timeRange.from ?? null}
                  className="datetimepicker"
                  onChange={(e) => handleChangeDateRange(e, "to")}
                />
              </LocalizationProvider>
            </label>
          </div>
          {loadingFilter ? (
            <button className="h-10 w-[110px] text-center text-white bg-black rounded-md flex justify-center items-center">
              <AiOutlineLoading className="animate-spin" />
            </button>
          ) : (
            <button
              onClick={() => {
                getData(pageNotifs);
              }}
              className="h-10 w-[110px] text-center text-white bg-black rounded-md"
            >
              Filter
            </button>
          )}
        </div>
        <div className="overflow-x-auto">
          <table>
            <thead className="h-10 bg-gray-200 rounded-sm">
              <tr className="h-full">
                <th className="min-h-20 px-3">#</th>
                <th className="min-h-20 px-3">Text</th>
                {/* <th className="min-h-20 px-3">To Address</th> */}
                <th className="min-h-20 px-3">Type</th>
                <th className="min-h-20 px-3">Time</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => (
                <tr className="h-10" key={item?.id}>
                  <td
                    className={`text-center ${
                      item.read ? "text-[#9B9DAC]" : "text-black"
                    } text-sm px-2 whitespace-nowrap`}
                  >
                    {(pageNotifs - 1) * 10 + index + 1}
                  </td>
                  <td
                    className={`text-center ${
                      item.read ? "text-[#9B9DAC]" : "text-black"
                    } text-sm px-2 whitespace-nowrap flex justify-center items-center`}
                  >
                    <div
                      className={`max-w-32 whitespace-nowrap overflow-hidden text-ellipsis`}
                    >
                      {item.text}
                    </div>
                  </td>
                  <td
                    className={`text-center ${
                      item.read ? "text-[#9B9DAC]" : "text-black"
                    } text-sm px-2 whitespace-nowrap`}
                  >
                    {item.type == 0 && "Deposit"}
                    {item.type == 1 && "Transaction"}
                    {item.type == 2 && "Low Balance"}
                    {item.type == 3 && "system"}
                    {item.type == 4 && "Update Plan"}
                  </td>
                  <td
                    className={`text-center ${
                      item.read ? "text-[#9B9DAC]" : "text-black"
                    } text-sm px-2 whitespace-nowrap`}
                  >
                    {moment(item?.time).format("jYYYY/jMM/jDD HH:mm:ss")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!list?.length && (
            <div className="flex justify-center items-center min-h-[420px] flex-col">
              <Nocontent />
              <div className="text-center">No data found!</div>
            </div>
          )}
        </div>
        <div className="mt-6">
          {count > 1 && (
            <PaginationComponent
              count={count}
              page={pageNotifs}
              onChange={(e) => {
                setPageNotifs(e);
                getData(e);
              }}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
}
