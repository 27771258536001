
export const timeHandler = (time) => {
  let now = new Date();
  if (now < time) {
    now.setDate(now.getDate() + 1);
  }
  let diff = new Date(now) - new Date(time);
  let ms = diff % 1000;
  let ss = Math.floor(diff / 1000) % 60;
  let mm = Math.floor(diff / 1000 / 60) % 60;
  let hh = Math.floor(diff / 1000 / 60 / 60);
  if (hh > 24) {
    let temp = Math.floor(hh / 24);
    return temp + "d ago";
  } else if (hh < 24 && hh > 1) {
    return hh + "h ago";
  }
  if (mm > 1 && mm < 59) {
    return mm + "m ago";
  }
  if (ss < 59 && ss > 1) {
    return ss + "s ago";
  }
  // console.log(`${diff}ms = ${hh}hr, ${mm}min, ${ss}sec, ${ms}ms`);
  // console.log(diff);
};
