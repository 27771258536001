import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useFormik } from "formik";
import moment from "jalali-moment";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import * as Yup from "yup";
import { APIServices } from "../../api";
import { ReactComponent as Copy } from "../../components/assets/copy.svg";
import { ReactComponent as Nocontent } from "../../components/assets/nocontent.svg";
import MainLayout from "../../components/layout/mainLayout";
import Modal from "../../components/shared/modal";
import { addressHandler } from "../../utils/addressHandler";
import snack from "../../utils/snack";
import CheckBalance from "./checkBalance";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
export default function DelegatePage() {
  const initialValues = {
    amount: "",
    address: "",
    rent_duration: "1d",
  };

  const [AllRequests, setAllRequests] = useState([]);

  const [userInfo, setUserInfo] = useState({});
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [listWallet, setListWallet] = useState([]);

  const rentTimes = ["1d", "3d", "7d", "14d", "30d"];

  const amountPackOfEnergy = [10, 20, 50, 100, 200, 500, 1000];

  const getDataInformation = () => {
    APIServices.getInformationsUser().then((res) => {
      setUserInfo(res.data.data);
    });
  };

  const getRequestList = () => {
    APIServices.getAllTransactions({
      page: 1,
      pageSize: 100,
      status: "0",
    }).then((res) => {
      // console.log(res.data);
      setList(res.data.data);
    });
  };

  const ValidationSchema = Yup.object().shape({
    amount: Yup.number().min(65000).required(),
    address: Yup.string().required(),
    rent_duration: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitForm(values, setSubmitting);
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
    validateForm,
    setSubmitting,
  } = formik;

  const getData = () => {
    APIServices.handleGetSaveaddress().then((res) => {
      setListWallet(res.data.data);
    });
  };

  const handleSubmitForm = (values, setSubmitting) => {
    let dto = {
      address: values.address,
      amount: values.amount,
      duration: values.rent_duration,
    };
    APIServices.delegateEnergy(dto, userInfo.apikeyvalue)
      .then((res) => {
        setAllRequests(res.data.ids);
        if (res.data.success && res.data.complete) {
          resetForm();
          snack.success(res.data.message);
        } else {
          snack.warning(res.data.message);
        }
      })
      .finally((e) => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    getDataInformation();
    getRequestList();
    getData();
    setInterval(() => {
      getRequestList();
    }, 5000);
  }, []);

  return (
    <MainLayout>
      <Modal
        onClose={() => {
          setShowModal(false);
        }}
        open={showModal}
        title={"Confirm delete address"}
      >
        <div className="flex flex-col gap-2 w-full my-2">
          <div className="text-base mb-4">
            Choose which wallet you want to charge
          </div>
          <div className="max-h-[400px] overflow-y-auto">
            {!listWallet.length ? (
              <div className="w-full flex items-center mt-4 flex-col">
                <Nocontent />
                <span>No Wallet saved</span>
              </div>
            ) : (
              listWallet?.map((item, index) => (
                <div className=" gap-1">
                  <div className="relative w-[5%] bottom-2 inline-block">
                    {index + 1}.{" "}
                  </div>
                  <div
                    className="justify-between w-[95%] inline-block my-2 flex-col px-2 py-1 bg-[#EAEAEA] rounded-md cursor-pointer"
                    onClick={() => {
                      setFieldValue("address", item.address);
                      setShowModal(false);
                    }}
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-1">
                      <span>{item.address}</span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </Modal>
      <div className="p-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="p-8 col-span-1 rounded-[20px] bg-white shadow-sm my-4">
            <div className="font-semibold mb-4">Delegate Energy here</div>
            <div className="flex flex-col gap-1 bg-[#eaeaea] rounded-md min-h-10 p-2">
              <div className="flex gap-1">
                <InfoOutlinedIcon />
                <span>Tip:</span>
              </div>
              <span className="p-2 rounded-md bg-[#B4D0F8] bg-opacity-55">
                In this service if amount is availabe and each transaction is
                50% and more cheaper than normal we send, otherwise change the
                amount or duration.
              </span>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="w-full mt-14"
              autoComplete="off"
            >
              <div className="input-container">
                <label className="!font-normal cursor-pointer">Amount</label>
                <input
                  value={values.amount}
                  autoFocus
                  type="number"
                  placeholder="amount minimum 65000"
                  className={`!pl-4 ${
                    touched.amount && errors.amount ? "error" : ""
                  }`}
                  onChange={(e) => setFieldValue("amount", e.target.value)}
                />
                <div className="flex gap-1 mt-1 overflow-x-auto pb-1">
                  {amountPackOfEnergy?.map((item, index) => (
                    <span
                      className={`mr-1 p-1 text-xs flex gap-0.5 cursor-pointer items-center rounded-md ${
                        values.amount / 65000 == item
                          ? "bg-[#00E9D0]"
                          : "bg-[#eaeaea]"
                      }`}
                      onClick={() => {
                        setFieldValue("amount", item * 65000);
                      }}
                    >
                      <span>X</span>
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              <div className="input-container">
                <div className="flex justify-between items-center whitespace-nowrap gap-1">
                  <label className="!font-normal cursor-pointer">
                    Your Address
                  </label>
                  <div
                    className="cursor-pointer link-auth text-ellipsis overflow-hidden"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Select from saved addresses
                  </div>
                </div>
                <input
                  value={values.address}
                  placeholder="address"
                  type={"text"}
                  className={`!pl-4 ${
                    touched.address && errors.address ? "error" : ""
                  }`}
                  onChange={(e) => setFieldValue("address", e.target.value)}
                />
                {values.address && (
                  <HighlightOffRoundedIcon
                    className="absolute right-2 top-9 cursor-pointer bg-white"
                    onClick={() => {
                      setFieldValue("address", "");
                    }}
                  />
                )}
              </div>
              <div className="my-8 flex gap-8 items-center">
                <label>Duration:</label>
                <div className="flex gap-2 items-center">
                  {rentTimes?.map((item, index) => (
                    <span
                      key={item}
                      className={`${
                        values.rent_duration == item
                          ? "text-black"
                          : "text-gray-400"
                      } cursor-pointer`}
                      onClick={() => {
                        setFieldValue("rent_duration", item);
                      }}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              {isSubmitting ? (
                <span type="submit" className="submit-btn">
                  <AiOutlineLoading className="animate-spin" />
                </span>
              ) : (
                <button type="submit" className="submit-btn">
                  Submit
                </button>
              )}
            </form>
          </div>
          <CheckBalance />
        </div>
        <div className="p-8 rounded-[20px] bg-white shadow-sm my-4">
          <div className="font-semibold mb-4">Uncompleted requests</div>
          <div className="overflow-auto">
            <table>
              <thead>
                <tr className="h-10 rounded-sm">
                  <th className="min-h-20 px-3">#</th>
                  <th className="min-h-20 px-3">Time</th>
                  {/* <th className="min-h-20 px-3">To Address</th> */}
                  <th className="min-h-20 px-3">Api key</th>
                  <th className="min-h-20 px-3">Energy amount</th>
                  <th className="min-h-20 px-3">Receive</th>
                  <th className="min-h-20 px-3">Status</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => (
                  <tr className="h-10" key={item?.id}>
                    <td className="text-center text-sm px-2 whitespace-nowrap">
                      {index + 1}
                    </td>
                    <td className="text-center text-sm px-2 whitespace-nowrap">
                      {moment(item?.createddate).format(
                        "jYYYY/jMM/jDD HH:mm:ss"
                      )}
                    </td>
                    <td className="text-center text-sm px-2 whitespace-nowrap">
                      <div className="flex gap-1 justify-center">
                        <span>
                          {item.apikey ? addressHandler(item.apikey) : "-"}
                        </span>
                        <Copy
                          fontSize="small"
                          className="cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(item.apikey);
                            snack.success("Copied.");
                          }}
                        />
                      </div>
                    </td>
                    <td className="text-center text-sm px-2 whitespace-nowrap">
                      {item?.energyused}
                    </td>
                    <td className="text-center text-sm px-2 whitespace-nowrap">
                      <div className="flex gap-1 justify-center">
                        <span>
                          {item.receive ? addressHandler(item.receive) : "-"}
                        </span>
                        {item.receive && (
                          <Copy
                            fontSize="small"
                            className="cursor-pointer"
                            onClick={() => {
                              navigator.clipboard.writeText(item.receive);
                              snack.success("Copied.");
                            }}
                          />
                        )}
                      </div>
                    </td>
                    <td className="text-center text-sm px-2 whitespace-nowrap flex gap-1 items-center">
                      <span>{item.status == "0" && "Pending"}</span>
                      {AllRequests?.includes(item.id) && (
                        <span className="inline-block w-2 h-2 rounded-full bg-green-400 animate-ping"></span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!list.length && (
              <div className="flex justify-center items-center min-h-[420px] flex-col">
                <Nocontent />
                <div className="text-center">No data found!</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
