// import { toast } from 'react-toastify'
import snack from "./snack";

export function handleSuccess(response) {
  // let notifs = JSON.parse(localStorage.getItem("notifs"))
  // if (response.data?.showNotification) {
  //   toast.success(notifs.filter(item => item.key == response.data?.code)[0]?.value, {
  //     toastId: 'successNotification',
  //   })
  //   return { data: response.data };
  // } else {
  return { data: response.data };
  // }
}

export function handleError(error) {
  // const history = usehistor
  if (error.message === "Network Error") {
    // The user doesn't have internet
    // toast.info('ارتباط با سرور برقرار نیست');
    return Promise.reject(error);
  }
  switch (error.response.status) {
    case 400:
      snack.error(error.response.data.message);
      break;

    case 401:
      window.location.assign('/');
      localStorage.removeItem("root")
      localStorage.removeItem("token")
      snack.error("You should be login first!");
      break;

    case 404:
      // Show 404 page
      // toast.error('مشکلی پیش آمد', 'پاسخی برای درخواست شما یافت نشد');
      snack.error(error.response.data.message);
      break;

    case 500:
      // Serveur Error redirect to 500
      snack.error(error.response.data.message);
      break;

    default:
      // Unknow Error
      break;
  }
  return Promise.reject(error);
}
