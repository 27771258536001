import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function PaginationComponent({ page, count, onChange }) {
  return (
    <div>
      <Pagination
        count={count}
        onChange={(e, v) => onChange(v)}
        page={page}
        color="primary"
        boundaryCount={2}
      />
    </div>
  );
}
