import moment from "jalali-moment";
import React, { useEffect, useState } from "react";
import { APIServices } from "../../api";
import MainLayout from "../../components/layout/mainLayout";
import { AiOutlineLoading } from "react-icons/ai";
import snack from "../../utils/snack";
import Modal from "../../components/shared/modal";
import ReactInputVerificationCode from "react-input-verification-code";
import ResendCountDown from "../../utils/ResendCountDown";
import SwitchButton from "../../components/shared/switch";
import { ReactComponent as Profile } from "../../components/assets/profile-active.svg";
import { ReactComponent as Pattern } from "../../components/assets/pattern.svg";
import { ReactComponent as Gallery } from "../../components/assets/gallery-add.svg";
import ChangePassword from "./changePassword";
import Settings from "./settings";
import { Tooltip } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { commafy2 } from "../../utils/commafy";
import SaveAddresses from "./saveAddresses";

export default function ProfilePage() {
  const [userInfo, setUserInfo] = useState({});
  const [minNumber, setMinNumber] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isErrorMobile, setIsErrorMobile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingMobile, setIsSubmittingMobile] = useState(false);
  const [mobile, setMobile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [retryCount, setRetryCount] = useState(1);
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [confirmCode, setConfirmCode] = useState("");
  const [mobileModal, setMobileModal] = useState(false);
  const [submtMobile, setSubmtMobile] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [count, setCount] = useState(0);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [notifs, setNotifs] = useState({
    depositnotification: false,
    transactionnotification: false,
    lowbalancenotification: false,
    depositemail: false,
    lowbalanceemail: false,
    systemnotification: false,
    plannotification: false,
    smslowbalance: false,
    smsplan: false,
    smsdeposit: false,
    update_plan_email: false,
  });

  const getData = () => {
    APIServices.getInformationsUser().then((res) => {
      setUserInfo(res.data.data);
      setMinNumber(res.data.data.minbalance);
      setNotifs({
        depositnotification: res.data.data.depositnotification,
        transactionnotification: res.data.data.transactionnotification,
        lowbalancenotification: res.data.data.lowbalancenotification,
        depositemail: res.data.data.depositemail,
        lowbalanceemail: res.data.data.lowbalanceemail,
        systemnotification: res.data.data.systemnotification,
        plannotification: res.data.data.plannotification,
        smslowbalance: res.data.data.smslowbalance,
        smsplan: res.data.data.smsplan,
        smsdeposit: res.data.data.smsdeposit,
        update_plan_email: res.data.data.update_plan_email,
      });
    });
  };

  const getUserPlanInformation = () => {
    APIServices.getAllUserRequestsCount().then((res) => {
      setCount(res.data.count);
    });
    APIServices.getAllUserPlansList().then((res) => {
      setPlanList(res.data.data);
    });
  };

  const handleChange = (val) => {
    if (val <= 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setMinNumber(val);
  };

  const hanldeSubmit = () => {
    setIsSubmitting(true);
    APIServices.updateMinBalance({ amount: Number(minNumber) })
      .then((res) => {
        if (res.data.success) {
          setMinNumber(0);
          snack.success(res.data.message);
          getData();
        }
      })
      .finally((e) => {
        setIsSubmitting(false);
      });
  };

  const hanldeSubmitMobile = () => {
    setIsSubmittingMobile(true);
    let dto = {
      mobile: mobile,
    };
    APIServices.handleAddMobileNumber(JSON.stringify(dto))
      .then((res) => {
        if (res.data.success) {
          snack.success(res.data.message);
          setShowModal(true);
          setMobileModal(false);
        }
      })
      .finally((e) => {
        setIsSubmittingMobile(false);
      })
      .catch((err) => {
        snack.warning(err.data.message);
      });
  };

  const confirmMobileNumber = (e) => {
    setSubmtMobile(true);
    let dto = { mobile: mobile, otpcode: e };
    APIServices.handleVerfiyMobileNumber(JSON.stringify(dto))
      .then((res) => {
        if (res.data.success) {
          snack.success(res.data.message);
          setShowModal(false);
          getData();
        }
      })
      .finally((e) => {
        setSubmtMobile(false);
      })
      .catch((err) => {
        snack.warning(err.data.message);
      });
  };

  let regex =
    /(0|\+98)?([ ]|-|[()]){0,2}9[1|2|3|4]([ ]|-|[()]){0,2}(?:[0-9]([ ]|-|[()]){0,2}){8}/g;
  const hanldeMobileChange = (e) => {
    setMobile(e.target.value);
    if (e.target.value.match(regex)) {
      setIsErrorMobile(false);
    } else {
      setIsErrorMobile(true);
    }
  };

  const handleResendCode = () => {
    setLoadingSendCode(true);
    APIServices.resendSMS({ mobile: mobile, email: userInfo.email }).then(
      (res) => {
        if (res.data.success) {
          setLoadingSendCode(false);
          snack.success(res.data.message);
          setRetryCount((prev) => prev + 1);
        }
      }
    );
  };

  useEffect(() => {
    getData();
    getUserPlanInformation();
  }, []);

  const handleChangeFiles = (e) => {
    // console.log(e.target.files);
    setLoadingUploadFile(true);
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    APIServices.uploadAvatar(formData)
      .then((res) => {
        // console.log(res.data);
        // if (res.data.success) {
        getData();
        snack.success(res.data.message);
        // }
      })
      .finally((e) => {
        setLoadingUploadFile(false);
      });
  };

  return (
    <MainLayout>
      <Modal
        onClose={() => {
          setMobileModal(false);
        }}
        open={mobileModal}
        title={"Add Mobile number"}
      >
        <div className="input-container">
          <input
            className={`!text-left !pl-4 ${isError ? "error" : ""}`}
            placeholder="Mobile number"
            value={mobile}
            type="number"
            onChange={(e) => {
              hanldeMobileChange(e);
            }}
          />
        </div>
        {isSubmittingMobile ? (
          <span type="submit" className="submit-btn w-full !mt-2">
            <AiOutlineLoading className="animate-spin" />
          </span>
        ) : (
          <button
            disabled={isErrorMobile || !mobile}
            onClick={hanldeSubmitMobile}
            className="submit-btn w-full !mt-3"
          >
            Submit
          </button>
        )}
      </Modal>
      <Modal
        onClose={() => {
          setShowModal(false);
        }}
        open={showModal}
        title={"Confirm Mobile number"}
      >
        <div className="flex flex-col gap-3">
          <div className="font-semibold text-lg">
            Enter code sent to {mobile}
          </div>
          <div className="verification-code-styles verification-code !text-white flex flex-col w-[260px] -mt-4">
            <ReactInputVerificationCode
              length={6}
              autoFocus
              onChange={(val) => setConfirmCode(val)}
              passwordMask="-"
              value={confirmCode}
              placeholder=""
              onCompleted={(e) => {
                confirmMobileNumber(e);
              }}
            />
            <div className="flex mt-2 justify-center">
              <ResendCountDown
                retryCount={retryCount}
                handleClick={handleResendCode}
                loading={loadingSendCode}
              />
            </div>
            {submtMobile ? (
              <span type="submit" className="submit-btn">
                <AiOutlineLoading className="animate-spin" />
              </span>
            ) : (
              <button
                onClick={() => confirmMobileNumber(confirmCode)}
                className="submit-btn"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </Modal>
      <div className="text-2xl mb-2 ml-1">Preferences</div>
      <div className="bg-white shadow-sm card !p-8">
        <div className="flex gap-1 items-center">
          <Profile />
          <span>Profile</span>
        </div>
        <div className="px-20 mt-8 mb-12 flex flex-wrap gap-8">
          <div className="flex flex-col">
            <label htmlFor="fileupload" className="relative">
              <input
                type="file"
                id="fileupload"
                className="hidden"
                onChange={(e) => {
                  handleChangeFiles(e);
                }}
              />
              {loadingUploadFile ? (
                <div className="h-20 w-20 rounded-full flex justify-center items-center">
                  <AiOutlineLoading className="animate-spin" />
                </div>
              ) : userInfo?.avatar ? (
                <img
                  src={"https://api.transferbyme.com/" + userInfo.avatar}
                  className="w-20 h-20 rounded-full"
                />
              ) : (
                <Pattern className="w-20 h-20 rounded-full" />
              )}
            </label>
            <span
              className={`max-w-20 py-0.5 px-2 text-white flex gap-1 items-center rounded-[4px] text-xs relative bottom-2 text-center ${
                userInfo?.plan?.id == "1"
                  ? "bg-[#BF9500]"
                  : userInfo?.plan?.id == "2"
                  ? "bg-[#9799A3]"
                  : "bg-[#BD6D40]"
              }`}
            >
              {userInfo?.plan?.name}
              <Tooltip
                placement="top-start"
                title={
                  <div className="bg-[#EAEAEA] text-sm min-w-48">
                    <div className="text-black">
                      Your plan is calculated based on the number of requests in
                      the last 30 days, it is{" "}
                      <span className="font-bold">{commafy2(count)}</span> for
                      you now. Plans are checked every day at 12 am.
                    </div>
                    <hr />
                    {planList?.map((plan, index) => {
                      const { id } = plan;
                      return (
                        <div
                          key={plan.id}
                          className={`h-10 text-black items-center flex justify-between`}
                        >
                          <span>{plan.name}</span>
                          <div className={`flex items-center gap-1`}>
                            {userInfo?.plan?.id == id && (
                              <CheckCircleOutlineRoundedIcon className="text-[#2BD483]" />
                            )}
                            <span className="">{commafy2(plan.count)}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                }
                arrow
              >
                <InfoOutlinedIcon
                  className="cursor-pointer text-white"
                  fontSize="small"
                />
              </Tooltip>
            </span>
          </div>
          {/* <div className={`flex items-center gap-1 col-span-1`}>
            <div className="text-[#9B9DAC] whitespace-nowrap">Username:</div>
            {userInfo?.username ? (
              <div className={`text-black whitespace-nowrap`}>{userInfo?.username}</div>
            ) : (
              <span className="text-[#2083FF] whitespace-nowrap cursor-pointer">
                Set Username
              </span>
            )}
          </div> */}
          <div className={`flex items-center gap-1`}>
            <div className="text-[#9B9DAC] whitespace-nowrap">Email:</div>
            <div className={`text-black whitespace-nowrap`}>
              {userInfo?.email}
            </div>
            <span className="text-[#2BD483] cursor-pointer">(verified)</span>
          </div>
          <div className={`flex items-center gap-1`}>
            <div className="text-[#9B9DAC] whitespace-nowrap">
              Phone Number:
            </div>
            {userInfo?.mobile ? (
              <div className={`text-black whitespace-nowrap`}>
                {userInfo?.mobile}
              </div>
            ) : (
              <span
                onClick={() => setMobileModal(true)}
                className="text-[#2083FF] whitespace-nowrap select-none cursor-pointer"
              >
                Set Phone Number
              </span>
            )}
          </div>
          <br />
          <div className="h-[1px] bg-[#9B9DAC] w-full"></div>
        </div>
        <ChangePassword />
        <Settings
          data={notifs}
          setMobileModal={() => {
            setMobileModal(true);
          }}
          lowBalance={minNumber}
          info={userInfo}
          getData={getData}
        />
        <SaveAddresses />
      </div>
      {/* <div className="grid grid-cols-1 md:gap-4 md:grid-cols-2 bg-white shadow-sm card">
        <div className="title-card col-span-2">Account Review</div>
        <div className="col-span-2 md:col-span-1 pr-4">
          <div className="flex justify-between items-center my-3">
            <div className=" text-base">Email</div>
            <div className="flex text-sm items-center justify-between">
              <span className=" text-black mr-1">
                {userInfo?.email ?? "--"}
              </span>
            </div>
          </div>
          {userInfo.mobile && (
            <div className="flex justify-between items-center my-3">
              <div className=" text-base">Mobile</div>
              <div className="flex text-sm items-center justify-between">
                <span className=" text-black mr-1">
                  {userInfo?.mobile ?? "--"}
                </span>
              </div>
            </div>
          )}
          <div className="flex justify-between items-center mt-3">
            <div className=" text-base">Minimum Balance</div>
            <div className="flex text-sm items-center justify-between">
              <span className=" text-black mr-1">
                {userInfo?.minbalance > 0 ? userInfo?.minbalance : "Not set"}
              </span>
            </div>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 md:pl-4">
          <div className="flex justify-between items-center mt-3">
            <div className=" text-base">Username</div>
            <div className=" text-sm">{userInfo?.username}</div>
          </div>
          <div className="flex text-sm my-3 justify-between items-center">
            <div className="">Last Login </div>
            <span className=" text-black mr-1">
              {moment(userInfo?.lastlogin).format("jYYYY/jMM/jDD hh:mm") ??
                "--"}
            </span>
          </div>
          <div className="flex text-sm my-3 justify-between items-center">
            <div className="">Register Date </div>
            <span className=" text-black mr-1">
              {moment(userInfo?.createddate).format("jYYYY/jMM/jDD hh:mm") ??
                "--"}
            </span>
          </div>
        </div>
      </div> */}
      {/* <div className="col-span-1 shadow-sm card my-4">
        <div className="title-card mb-3">System settings</div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <p className="col-span-1">
            Minimum Blanace : you can set a minimum balance for your account if
            the balance reaches this number with send you a notification.
          </p>
          <p className="col-span-1">
            {!userInfo.mobile && "Add "} Mobile Number
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex col-span-1 justify-between md:border-r gap-2 md:pr-4 items-center">
            <div className="input-container !w-1/2">
              <input
                className={`!text-left !pl-4 ${isError ? "error" : ""}`}
                placeholder="Type a number"
                value={minNumber}
                type="number"
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              />
            </div>
            {isSubmitting ? (
              <span type="submit" className="submit-btn max-w-32 !mt-2">
                <AiOutlineLoading className="animate-spin" />
              </span>
            ) : (
              <button
                disabled={
                  isError || !minNumber || minNumber == userInfo?.minbalance
                }
                onClick={hanldeSubmit}
                className="submit-btn max-w-32 !mt-3"
              >
                Send
              </button>
            )}
          </div>
          <div className="flex col-span-1 justify-between gap-2 pl-4 items-center">
            {!userInfo.mobile ? (
              <>
                <div className="input-container !w-1/2">
                  <input
                    className={`!text-left !pl-4 ${isError ? "error" : ""}`}
                    placeholder="Mobile number"
                    value={mobile}
                    type="number"
                    onChange={(e) => {
                      hanldeMobileChange(e);
                    }}
                  />
                </div>
                {isSubmittingMobile ? (
                  <span type="submit" className="submit-btn max-w-32 !mt-2">
                    <AiOutlineLoading className="animate-spin" />
                  </span>
                ) : (
                  <button
                    disabled={isErrorMobile || !mobile}
                    onClick={hanldeSubmitMobile}
                    className="submit-btn max-w-32 !mt-3"
                  >
                    Submit
                  </button>
                )}
              </>
            ) : (
              <>
                <div className="w-1/2">{userInfo?.mobile}</div>
                <button
                  disabled={!userInfo?.mobile}
                  onClick={hanldeChangeMobile}
                  className="submit-btn max-w-32 !mt-3"
                >
                  Change Mobile
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-1 shadow-sm card mt-4 mb-8">
        <div className="title-card col-span-2">Notification Settings</div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 md:pr-4">
            <div className="flex justify-between items-center mt-3">
              <div className="text-base">Email when deposit</div>
              <div className="text-sm">
                <SwitchButton
                  checked={notifs.depositemail}
                  onChange={(e) => handleChangeSwitch(e, "depositemail")}
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-3">
              <div className="text-base">Notification when deposit</div>
              <div className="text-sm">
                <SwitchButton
                  checked={notifs.depositnotification}
                  onChange={(e) => handleChangeSwitch(e, "depositnotification")}
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-3">
              <div className="text-base">Email in Low Balance</div>
              <div className="text-sm">
                <SwitchButton
                  checked={notifs.lowbalanceemail}
                  onChange={(e) => handleChangeSwitch(e, "lowbalanceemail")}
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-3">
              <div className="text-base">Notification in Low Balance</div>
              <div className="text-sm">
                <SwitchButton
                  checked={notifs.lowbalancenotification}
                  onChange={(e) =>
                    handleChangeSwitch(e, "lowbalancenotification")
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-span-1 md:pl-4">
            <div className="flex justify-between items-center mt-3">
              <div className="text-base">Notification in update Plan</div>
              <div className="text-sm">
                <SwitchButton
                  checked={notifs.plannotification}
                  onChange={(e) => handleChangeSwitch(e, "plannotification")}
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-3">
              <div className="text-base">Notification in any transaction</div>
              <div className="text-sm">
                <SwitchButton
                  checked={notifs.transactionnotification}
                  onChange={(e) =>
                    handleChangeSwitch(e, "transactionnotification")
                  }
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-3">
              <div className="text-base">System notification (login , ...)</div>
              <div className="text-sm">
                <SwitchButton
                  checked={notifs.systemnotification}
                  onChange={(e) => handleChangeSwitch(e, "systemnotification")}
                />
              </div>
            </div>
            <div className="flex justify-center pt-3">
              {loadingUpdate ? (
                <span type="submit" className="submit-btn max-w-32 !mt-0">
                  <AiOutlineLoading className="animate-spin" />
                </span>
              ) : (
                <button
                  className="mx-auto submit-btn max-w-32 !mt-0"
                  onClick={handleUpdateNotoification}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </MainLayout>
  );
}
