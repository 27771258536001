import Cookies from "js-cookie";

export function authHeader() {
  // return authorization header with jwt user
  // let token = localStorage.getItem('paybymeUser');
  const user = Cookies.get("paybymeUser");

  if (user) {
    let token = user.token;
    // console.log(token);
    return {
      Authorization: "Bearer " + token,
      apiKey: "1b285fe3-935f-43f2-9a12-0eaace9f0607",
      apiSecret: "8d91944f-ee3c-4d44-9826-6275148637ec",
    };
  } else {
    return {};
  }
}

export function authHeaderJson() {
  const user = localStorage.getItem("token");

  // if (user) {
  // console.log(token);
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + user,
  };
}

export function authHeaderJsonDelegate(apikey) {
  const user = localStorage.getItem("token");

  // if (user) {
  // console.log(token);
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + user,
    apiKey: apikey
  };
}

export function headerJson() {
  return {
    "Content-Type": "application/json",
  };
}

export function headerJsonUpload() {
  const user = localStorage.getItem("token");
  return {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + user
  };
}
