import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import * as React from 'react';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 47,
  height: 24,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 26,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(5px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    transform: 'translateX(-2px)',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#2083FF' : '#2083FF',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 20,
    height: 20,
    transform: 'translateX(3px)',
    borderRadius: 11,
    backgroundColor: "#fff",
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 30,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? '#D0D0D0' : '#D0D0D0',
    boxSizing: 'border-box',
  },
}));


export default function SwitchButton(props) {
  return (
    <div>
      <AntSwitch disabled={props.disabled} checked={props.checked} onChange={props.onChange} inputProps={{ 'aria-label': 'ant design' }} />
    </div>
  )
}
