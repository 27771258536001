import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/fulllogo.svg";

import { ReactComponent as WebsiteActive } from "../assets/Website-active.svg";
import { ReactComponent as Website } from "../assets/Website.svg";
import { ReactComponent as CloudActive } from "../assets/cloud-active.svg";
import { ReactComponent as Cloud } from "../assets/cloud-connection.svg";
import { ReactComponent as DashboardActive } from "../assets/dashboard-active.svg";
import { ReactComponent as Dashboard } from "../assets/dashboard.svg";
import { ReactComponent as Logout } from "../assets/logout.svg";
import { ReactComponent as ReceiptActive } from "../assets/receipt-active.svg";
import { ReactComponent as Receipt } from "../assets/receipt.svg";
import { ReactComponent as ProfileActive } from "../assets/settingactive.svg";
import { ReactComponent as Profile } from "../assets/setting.svg";
import { ReactComponent as Menulogo } from "../assets/menulogo.svg";
import { ReactComponent as Document } from "../assets/document-text.svg";
import { ReactComponent as Pattern } from "../assets/pattern.svg";
import { ReactComponent as NotificationActive } from "../assets/Notification.svg";
import { ReactComponent as Notificationdeactive } from "../assets/Notificationdeactive.svg";
import { ReactComponent as Patterndeactive } from "../assets/Patterndeactive.svg";
import { clearUser } from "../store/actions/user";
import ExplicitRoundedIcon from '@mui/icons-material/ExplicitRounded';
import BottomMenu from "../assets/menubg.png";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import { ClickAwayListener } from "@mui/base";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import Fade from "@mui/material/Fade";
import CalculateRoundedIcon from "@mui/icons-material/CalculateRounded";
import { APIServices } from "../../api";
import { commafy2 } from "../../utils/commafy";
import { timeHandler } from "../../utils/timeHandler";
import PriceChangeRoundedIcon from "@mui/icons-material/PriceChangeRounded";
// import io from "socket.io-client";

export default function MainLayout({ children }) {
  // const socket = io();
  const [open, setOpen] = useState(true);
  const [showSidebar, setShowSidebar] = useState("-left-[240px]");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState({});

  const [notifBox, setNotifBox] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [style, setStyle] = useState("-right-[400px] opacity-0");
  const [list, setList] = useState([]);

  function IncludeMenu(menu) {
    var res = false;
    const location = useLocation();
    if (
      location.pathname.toLocaleLowerCase().includes(menu.toLocaleLowerCase())
    ) {
      res = true;
    }
    return res;
  }

  function IsActiveMenu(menu) {
    var res = false;
    const location = useLocation();
    if (location.pathname.toLocaleLowerCase() == menu.toLocaleLowerCase()) {
      res = true;
    }
    return res;
  }

  const handleToggle = () => {
    if (showSidebar == "left-0") {
      setShowSidebar("-left-[240px]");
    } else {
      setShowSidebar("left-0");
    }
  };

  const handleLogout = () => {
    navigate("/login");
    dispatch(clearUser);
    localStorage.removeItem("root");
    localStorage.removeItem("token");
  };

  const getNotifList = () => {
    if (showMenu && notifBox) {
      APIServices.getAllNotificationList({ page: 1, pageSize: 10 }).then(
        (res) => {
          setList(res.data.data);
        }
      );
    }
  };

  const getData = () => {
    APIServices.getInformationsUser().then((res) => {
      // console.log(res.data.data);
      setUserInfo(res.data.data);
    });
  };

  const handleClickPattern = () => {
    setNotifBox(false);
    setStyle("opacity-100 right-0");
    setShowMenu(true);
  };

  const handleClickNotifs = () => {
    setNotifBox(true);
    setStyle("opacity-100 right-0");
    setShowMenu(true);
  };

  const handleClickAway = () => {
    setStyle("-right-[400px] opacity-0");
    // setTimeout(() => {
    setNotifBox(false);
    setShowMenu(false);
    // }, 1000);
  };

  useEffect(() => {
    if (showMenu) {
      getData();
    }
  }, [showMenu]);

  useEffect(() => {
    if (notifBox && showMenu) {
      getNotifList();
    }
  }, [notifBox, showMenu]);

  useEffect(() => {
    if (notifBox && showMenu) {
      if (list.some((el) => !el.read)) {
        APIServices.UpdateNotification().then((res) => {
          if (res.data.success) {
            let temp = [...list];
            let helper = [];
            temp.map((item) => {
              helper.push({
                ...item,
                read: true,
              });
            });
            setList(helper);
          }
        });
      }
    }
  }, [notifBox, showMenu]);

  return (
    <>
      <div className="relative pt-20">
        <div className="flex absolute w-full top-0 right-0 justify-between items-center px-4 py-2 min-h-12">
          <div className="flex items-center">
            <span
              className={`inline-block lg:hidden transition-all relative ${
                showSidebar == "-left-[240px]" ? "opacity-100" : "opacity-0"
              }`}
            >
              <MenuOutlinedIcon
                className="hidden mx-4 cursor-pointer"
                onClick={handleToggle}
              />
            </span>
          </div>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              // onMouseLeave={handleClickAway}
              className={`flex gap-2 w-44 justify-between h-16 transition-all relative bg-white ${
                !showMenu
                  ? "rounded-full"
                  : "rounded-tr-[40px] rounded-tl-[40px]"
              } p-2 items-center shadow-sm`}
            >
              <div className={`${style} sub-menu`}>
                {notifBox ? (
                  <div
                    className={`transition-all p-6 !pb-4 ${
                      notifBox ? "h-[418px] opacity-100" : "opacity-0 h-0"
                    }`}
                  >
                    <div className="h-[calc(100%-50px)] overflow-y-auto">
                      {list?.map((item, index) => {
                        return (
                          <Link
                            to="/notifications"
                            className="pb-2 h-[88px] pt-3 block border-b"
                          >
                            <span
                              className={`text-sm ${
                                item.read ? "text-[#9B9DAC]" : "text-black"
                              }`}
                            >
                              {item.type == 0 && "Deposit"}
                              {item.type == 1 && "Transaction"}
                              {item.type == 2 && "Low Balance"}
                              {item.type == 3 && "system"}
                              {item.type == 4 && "Update Plan"}
                            </span>
                            <div
                              className={`text-sm whitespace-nowrap overflow-hidden text-ellipsis ${
                                item.read ? "text-[#9B9DAC]" : "text-black"
                              }`}
                            >
                              {item.text}
                            </div>
                            <div className={`flex justify-between`}>
                              <span className="text-[#9B9DAC] text-[10px] mt-1">
                                {timeHandler(item.time)}
                              </span>
                              {item?.link && (
                                <Link
                                  href={item.link}
                                  className="text-[#2083FF] cursor-pointer text-xs"
                                >
                                  Notification Link
                                </Link>
                              )}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                    <div
                      className="h-[50px] gap-1 flex items-end justify-center text-[#2083FF] cursor-pointer"
                      onClick={() => {
                        setNotifBox(false);
                      }}
                    >
                      <span>Profile</span>
                      <EastRoundedIcon />
                    </div>
                  </div>
                ) : (
                  <div
                    className={` transition-all p-6 !pb-4 ${
                      !notifBox ? "opacity-100 h-[176px]" : "opacity-0 h-0"
                    }`}
                  >
                    <div className={`flex flex-col`}>
                      <div className={`border-b flex gap-1 justify-between`}>
                        {userInfo && (
                          <div className="flex flex-col">
                            {userInfo?.avatar ? (
                              <img
                                src={
                                  "https://api.transferbyme.com/" +
                                  userInfo.avatar
                                }
                                className="w-20 h-20 rounded-full"
                              />
                            ) : (
                              <Pattern className="w-20 h-20 rounded-full" />
                            )}
                            {/* <Pattern className="w-20 h-20 rounded-full" /> */}
                            <span
                              className={`inline-block py-0.5 px-2 text-white rounded-[4px] text-xs relative bottom-2 text-center ${
                                userInfo?.plan?.id == "1"
                                  ? "bg-[#BF9500]"
                                  : userInfo?.plan?.id == "2"
                                  ? "bg-[#9799A3]"
                                  : "bg-[#BD6D40]"
                              }`}
                            >
                              {userInfo?.plan?.name}
                            </span>
                          </div>
                        )}
                        <div className="flex flex-col overflow-hidden gap-6">
                          <span className="text-base text-black overflow-hidden relative top-2 whitespace-nowrap text-ellipsis">
                            {userInfo?.email}
                          </span>
                          <div className={`flex items-center gap-1`}>
                            <div className="text-[#9B9DAC]">Balance:</div>
                            <div className={`text-black`}>
                              {commafy2(userInfo?.balance?.toFixed(2))} TRX
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="h-[50px] gap-1 flex items-center justify-center text-[#2083FF] cursor-pointer"
                        onClick={() => {
                          setNotifBox(true);
                        }}
                      >
                        <WestRoundedIcon />
                        <span>Notifications</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="w-1/2 flex justify-center relative"
                // onClick={handleClickNotifs}
                onMouseEnter={handleClickNotifs}
              >
                <Link to="/notifications">
                  {notifBox || !showMenu ? (
                    <NotificationActive className={`cursor-pointer`} />
                  ) : (
                    <Notificationdeactive className={`cursor-pointer`} />
                  )}
                </Link>
                {list?.some((el) => !el.read) && (
                  <span className="w-1.5 h-1.5 rounded-full inline-block bg-[#FF3E3E]"></span>
                )}
              </div>
              <div className="border-l h-full"></div>
              {!notifBox || !showMenu ? (
                userInfo?.avatar ? (
                  <img
                    src={"https://api.transferbyme.com/" + userInfo.avatar}
                    onMouseEnter={handleClickPattern}
                    className="w-1/2 h-12 rounded-full"
                  />
                ) : (
                  <Pattern
                    className={`w-1/2 cursor-pointer`}
                    onMouseEnter={handleClickPattern}
                  />
                  // <Pattern className="w-12 h-12 rounded-full" />
                )
              ) : userInfo?.avatar ? (
                <img
                  src={"https://api.transferbyme.com/" + userInfo.avatar}
                  onMouseEnter={handleClickPattern}
                  className="w-1/2 h-12 rounded-full"
                />
              ) : (
                // <Pattern className="w-12 h-12 rounded-full" />
                <Patterndeactive
                  className={`w-1/2 cursor-pointer`}
                  onMouseEnter={handleClickPattern}
                />
              )}
            </div>
          </ClickAwayListener>
        </div>
        <div
          className={`flex relative -top-20 bg-black bg-opacity-20 ${
            showSidebar == "-left-[240px]" ? "gap-3" : ""
          } lg:gap-3 z-[51]`}
        >
          {/* {showSidebar == "left-0" && ( */}
          <span
            className={`cursor-pointer transition-all duration-500 fixed ${
              showSidebar == "left-0"
                ? "left-[230px] opacity-100"
                : "left-0 opacity-0"
            } lg:hidden z-[100] bg-[#000] rounded-md p-1 text-white`}
            onClick={() => {
              setShowSidebar("-left-[240px]");
            }}
          >
            <ClearRoundedIcon />
          </span>
          {/* )} */}
          {showSidebar == "left-0" && (
            <div
              className="bg-black opacity-20 fixed left-0 top right-0 bottom-0 h-screen lg:hidden"
              onClick={() => {
                setShowSidebar("-left-[240px]");
              }}
            ></div>
          )}
          <div
            className={`fixed h-screen text-white side-bar ${showSidebar} lg:left-0 overflow-y-auto min-h-fit z-50 flex-row flex-nowrap bg-white w-[240px] py-4 transition-all duration-300 shadow`}
          >
            <div className="flex justify-center mb-12">
              <Menulogo />
            </div>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/dashboard")
                  ? " text-[#000000]"
                  : "text-[#9EA0AA]"
              }`}
              to="/dashboard"
            >
              {IsActiveMenu("/dashboard") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IsActiveMenu("/dashboard") ? <DashboardActive /> : <Dashboard />}
              <span className="flex items-center">Dashboard</span>
            </Link>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/preferences")
                  ? " text-[#000000]"
                  : "text-[#9EA0AA]"
              }`}
              to="/preferences"
            >
              {IsActiveMenu("/preferences") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IsActiveMenu("/preferences") ? <ProfileActive /> : <Profile />}
              <span className="flex items-center">Preferences</span>
            </Link>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/transactions")
                  ? " text-[#000000]"
                  : "text-[#9EA0AA]"
              }`}
              to="/transactions"
            >
              {IncludeMenu("/transactions") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IncludeMenu("/transactions") ? <ReceiptActive /> : <Receipt />}
              <span className="flex items-center">Transactions</span>
            </Link>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/apikey") ? " text-[#000000]" : "text-[#9EA0AA]"
              }`}
              to="/apikey"
            >
              {IncludeMenu("/apikey") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IsActiveMenu("/apikey") ? <CloudActive /> : <Cloud />}
              <span className="flex items-center">Api Key</span>
            </Link>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/userip") ? " text-[#000000]" : " text-[#9EA0AA]"
              }`}
              to="/userip"
            >
              {IncludeMenu("/userip") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IsActiveMenu("/userip") ? <WebsiteActive /> : <Website />}
              <span className="flex items-center">Ip</span>
            </Link>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/estimate")
                  ? " text-[#000000]"
                  : " text-[#9EA0AA]"
              }`}
              to="/estimate"
            >
              {IncludeMenu("/estimate") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IsActiveMenu("/estimate") ? (
                <CalculateRoundedIcon />
              ) : (
                <CalculateRoundedIcon />
              )}
              <span className="flex items-center">Estimate</span>
            </Link>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/delegate")
                  ? " text-[#000000]"
                  : " text-[#9EA0AA]"
              }`}
              to="/delegate"
            >
              {IncludeMenu("/delegate") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IsActiveMenu("/delegate") ? (
                <ExplicitRoundedIcon />
              ) : (
                <ExplicitRoundedIcon />
              )}
              <span className="flex items-center">Delegate</span>
            </Link>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/plans") ? " text-[#000000]" : "text-[#9EA0AA]"
              }`}
              to="/plans"
            >
              {IsActiveMenu("/plans") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IsActiveMenu("/plans") ? (
                <PriceChangeRoundedIcon />
              ) : (
                <PriceChangeRoundedIcon className="" />
              )}
              <span className="flex items-center">Plans</span>
            </Link>
            <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/document")
                  ? " text-[#000000]"
                  : " text-[#9EA0AA]"
              }`}
              to="https://doc.transferbyme.com/documentation"
              target="_blank"
            >
              {IncludeMenu("/document") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              {IsActiveMenu("/userip") ? <Document /> : <Document />}
              <span className="flex items-center">Document</span>
            </Link>
            {/* <Link
              className={`relative pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md ${
                IsActiveMenu("/settings")
                  ? " text-[#294aa5]"
                  : ""
              }`}
              to="/settings"
            >
              {IncludeMenu("/settings") && (
                <span
                  className={`h-10 w-[4px] bg-[#00E9D0] rounded-sm absolute right-1 !lg:inline-block ${
                    open ? "inline-block" : "hidden"
                  }`}
                ></span>
              )}
              <SettingsRoundedIcon className="text-black" />
              <span className="flex items-center">Settings</span>
            </Link> */}
            <span
              className={`relative cursor-pointer pl-6 flex gap-2 items-center min-h-[46px] rounded-tr-md rounded-br-md`}
              onClick={handleLogout}
            >
              <Logout />
              <span className="flex items-center text-[#9EA0AA]">Logout</span>
            </span>
            <img src={BottomMenu} className="absolute bottom-0" />
          </div>
        </div>
        <div className={`lg:pl-[252px] transition-all relative mt-4 px-4`}>
          {children}
        </div>
      </div>
    </>
  );
}
