import { useFormik } from "formik";
import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { APIServices } from "../api";
import Email from "../components/assets/email.svg";
import Header from "../components/shared/header";
import { setUserData } from "../components/store/actions/user";
import snack from "../utils/snack";
import ForgetPasswordStep2 from "./components/forgetPasswordStep2";
import Logo from "../components/assets/logo.svg";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ReCAPTCHA from "react-google-recaptcha";

export default function ForgetPassword() {
  const [step, setStep] = useState(1);
  const [userId, setuserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingFinal, setLoadingFinal] = useState(false);
  const initialValues = {
    email: "",
    captcha: "",
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email().required("email"),
    captcha: Yup.string().required(),
  });

  const goBack = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitForm(values, setSubmitting);
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleChange,
    resetForm,
    handleSubmit,
    validateForm,
    setSubmitting,
  } = formik;

  const handleSubmitForm = (values, setSubmitting) => {
    let dto = {
      ...values,
      email: values.email.toLowerCase(),
    };
    APIServices.requestForgetPassword(JSON.stringify(dto))
      .then((res) => {
        snack.success(res.data.message);
        setStep(2);
        setUserEmail(values.email);
        setuserId(res.data.id);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((e) => {
        setSubmitting(false);
      });
  };

  const handleConfirmAccount = (data) => {
    setLoadingFinal(true);
    let dto = {
      id: userId,
      otpcode: data.code,
      password: data.password,
    };
    APIServices.authorizeForgetPassword(JSON.stringify(dto))
      .then((res) => {
        snack.success("Your password changed successfully.");
        localStorage.setItem("token", res.data.accessToken);
        dispatch(setUserData(res.data.user));
        navigate("/dashboard");
      })
      .catch((err) => {
        // snack.error("Something went wrong.");
      })
      .finally((e) => {
        setLoadingFinal(false);
      });
  };

  const onChangeCaptcha = (e) => {
    setFieldValue("captcha", e);
  };

  return (
    <div className="auth-bg">
      <Header />
      <div className="auth-wrapper">
        {/* <p className="text-[#111111] text-[30px] mb-2 font-bold">
          Forget Password
        </p> */}
        <div className="flex gap-1 flex-wrap items-center text-[40px] tracking-wider">
          <span className="font-extralight whitespace-nowrap">Welcome to</span>
          <p className="text-[#111111] text-[40px] font-bold whitespace-nowrap">
            TransferByMe
          </p>
        </div>
        <br />
        <div className="box-auth">
          <img className="mx-auto mt-3 mb-12" src={Logo} />
          {step == 1 ? (
            <div className="flex text-[17px] text-black text-justify gap-5 items-center">
              Enter the email address associated with your account, and we’ll
              email you a code to reset your password.
            </div>
          ) : (
            <>
              <div className="flex text-[17px] text-black text-justify gap-5 items-center">
                Enter the 6-digits verification code sent to
              </div>
              <div>{userEmail ?? ""}</div>
            </>
          )}
          {step == 1 ? (
            <form
              onSubmit={formik.handleSubmit}
              className="w-full"
              autoComplete="off"
            >
              <div className="input-container mt-8">
                <input
                  value={values.email}
                  type="email"
                  autoFocus
                  placeholder="Email"
                  className={`${touched.email && errors.email ? "error" : ""}`}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />
                <img src={Email} className="icon" />
              </div>

              <div className="mb-8 flex captchaContainer justify-center flex-col items-center">
                <ReCAPTCHA
                  sitekey="6LdHhtwpAAAAADou_7XeQbOSoSzNyvnVI8jgFAbQ"
                  onChange={onChangeCaptcha}
                />
                {errors.captcha && (
                  <div className="text-[#ef6a6a] text-sm">
                    Captcha is required.
                  </div>
                )}
              </div>
              {isSubmitting ? (
                <span type="submit" className="submit-btn">
                  <AiOutlineLoading className="animate-spin" />
                </span>
              ) : (
                <button type="submit" className="submit-btn">
                  Send code
                </button>
              )}
            </form>
          ) : (
            <>
              <ForgetPasswordStep2
                userId={userId}
                email={values.email}
                loading={loadingFinal}
                handleConfirmAccount={handleConfirmAccount}
              />
            </>
          )}
          <div className="flex justify-center cursor-pointer" onClick={goBack}>
            <KeyboardArrowLeftRoundedIcon className="link-auth" />
            <span className="link-auth cursor-pointer">Back</span>
          </div>
        </div>
      </div>
    </div>
  );
}
