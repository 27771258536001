import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme, maxWidth }) => ({
  "& .MuiDialog-container": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(1px)",
  },
  "& .MuiPaper-root": {
    borderRadius: "5px!important",
    width: "80%",
    maxWidth: maxWidth ? maxWidth : "460px",
    minWidth: "300px",
    backgroundColor: "#ffffff",
    padding: "20px 40px!important"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
    borderTop: "0!important",
    borderBottom: "0!important",
  },
  "& .MuiTypography-root": {
    backgroundColor: "#ffffff",
    textAlign: "left",
    color: "#000",
    paddingBottom: "5px!important",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogTitle-root": {
    color: "#000",
    borderTopLeftRadius: "5px!important",
    borderTopRightRadius: "5px!important",
    overflow: "hidden",
    textTransform: "uppercase!important",
    marginTop: "8px!important",
    fontSize: "13px!important",
    padding: "0!important",
    display: "flex"
  },
  "& .MuiDialogTitle-root .MuiSvgIcon-root": {
    color: "#000!important",
  },
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(3px)!important",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 1,
        borderTopRightRadius: "6px",
        borderTopLeftRadius: "6px",
        fontSize: "16px",
        color: "#EAECEF",
      }}
      {...other}
    >
      {children}
      {/* {onClose ? ( */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#4e5873",
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      {/* ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Modal({
  title,
  children,
  open,
  style,
  maxWidth,
  keepMounted,
  onClose
}) {

  const handleClose = () => {
    onClose()
  };

  return (
    <div style={style}>
      <BootstrapDialog
        onClose={handleClose}
        maxWidth={maxWidth}
        aria-labelledby="customized-dialog-title"
        open={open}
        keepMounted={false}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span className="text-[#000] mx-auto !normal-case font-bold text-xl !mb-4 py-3">{title}</span>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          // sx={{ padding: "20px 30px 10px 30px !important" }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
