import React from "react";
import { useSelector } from "react-redux";
import Header from "../../components/shared/header";

export default function Document() {
  const user = useSelector((state) => state.user);
  return (
    <div>
      <Header />
      <div className="p-4 mt-5">
        <h4>Request charge wallet</h4>
        <div className="flex gap-1 mt-3">
          <span className="rounded-xl py-1 px-3 bg-blue-800 text-white text-xs">
            Post
          </span>
          <span>https://api.transferbyme.com/api/transfer/request</span>
        </div>
      </div>
      <div className="p-4">
        <table>
          <thead className="h-12 bg-gray-300">
            <tr className="h-12">
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Field
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Type
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Description
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Position
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Example
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">apikey</td>
              <td className="text-sm text-left pl-2">string</td>
              <td className="text-sm text-left pl-2">
                The tron address that you need transfer usdt to it
              </td>
              <td className="text-sm text-left pl-2">Header of request</td>
              <td className="text-sm text-left pl-2">f4e7d8b4n01bvcfc924dce91</td>
            </tr>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">address</td>
              <td className="text-sm text-left pl-2">string</td>
              <td className="text-sm text-left pl-2">
                Your tron address you need transfer from it, we record this for
                your transactions
              </td>
              <td className="text-sm text-left pl-2">Body parmeter</td>
              <td className="text-sm text-left pl-2">TVE4MNVF9DCLWFmrHdEUzRTY2csBPA9hkM</td>
            </tr>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">amount</td>
              <td className="text-sm text-left pl-2">Number</td>
              <td className="text-sm text-left pl-2">
                Amount of usdt you need transfer, we record this for your
                transactions
              </td>
              <td className="text-sm text-left pl-2">Body parmeter</td>
              <td className="text-sm text-left pl-2">80</td>
            </tr>
          </tbody>
        </table>
        <div className="mt-5 flex gap-2">
          <span className="text-sm font-semibold whitespace-nowrap">
            Example :{" "}
          </span>
          <div className="">
            https://api.transferbyme.com/api/transfer/request
          </div>
        </div>
      </div>
      <div className="p-4 mt-5">
        <h4>Check balance of your wallet</h4>
        <div className="flex gap-1 mt-3">
          <span className="rounded-xl py-1 px-3 bg-green-700 text-white text-xs">
            Get
          </span>
          <span>https://api.transferbyme.com/api/user/getbalance</span>
        </div>
      </div>
      <div className="p-4">
        <table>
          <thead className="h-12 bg-gray-300">
            <tr className="h-12">
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Field
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Type
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Description
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Position
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Example
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">apikey</td>
              <td className="text-sm text-left pl-2">string</td>
              <td className="text-sm text-left pl-2">
                The tron address that you need transfer usdt to it
              </td>
              <td className="text-sm text-left pl-2">Header of request</td>
              <td className="text-sm text-left pl-2">
                f4e7d8b4n01bvcfc924dce91
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-5 flex gap-2">
          <span className="text-sm font-semibold whitespace-nowrap">
            Example :{" "}
          </span>
          <div className="">
            https://api.transferbyme.com/api/user/getbalance
          </div>
        </div>
      </div>
      <div className="p-4 mt-5">
        <h4>Check balance of your wallet</h4>
        <div className="flex gap-1 mt-3">
          <span className="rounded-xl py-1 px-3 bg-blue-800 text-white text-xs">
            Post
          </span>
          <span>https://api.transferbyme.com/api/user/gettransactions</span>
        </div>
      </div>
      <div className="p-4">
        <table>
          <thead className="h-12 bg-gray-300">
            <tr className="h-12">
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Field
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Type
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Description
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Position
              </th>
              <th className="font-semibold text-black text-sm pl-2 text-left">
                Example
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">apikey</td>
              <td className="text-sm text-left pl-2">string</td>
              <td className="text-sm text-left pl-2">
                The tron address that you need transfer usdt to it
              </td>
              <td className="text-sm text-left pl-2">Header of request</td>
              <td className="text-sm text-left pl-2">
                f4e7d8b4n01bvcfc924dce91
              </td>
            </tr>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">from</td>
              <td className="text-sm text-left pl-2">string</td>
              <td className="text-sm text-left pl-2">
                start Date you need to filter
              </td>
              <td className="text-sm text-left pl-2">Body parameter</td>
              <td className="text-sm text-left pl-2">2024-03-01 00:00:00</td>
            </tr>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">to</td>
              <td className="text-sm text-left pl-2">string</td>
              <td className="text-sm text-left pl-2">
                end Date you need to filter
              </td>
              <td className="text-sm text-left pl-2">Body parameter</td>
              <td className="text-sm text-left pl-2">2024-03-16 23:59:59</td>
            </tr>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">page</td>
              <td className="text-sm text-left pl-2">number</td>
              <td className="text-sm text-left pl-2">
                for paginate return data
              </td>
              <td className="text-sm text-left pl-2">Body parameter</td>
              <td className="text-sm text-left pl-2">1</td>
            </tr>
            <tr className="bg-white h-12 border-b">
              <td className="text-sm text-left pl-2">pageSize</td>
              <td className="text-sm text-left pl-2">number</td>
              <td className="text-sm text-left pl-2">
                records will return in count
              </td>
              <td className="text-sm text-left pl-2">Body parameter</td>
              <td className="text-sm text-left pl-2">100</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
