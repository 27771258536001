import { CLEAR_USER, SET_ASSET } from '../actions/asset'

const initialState = {}

function assetReducer(state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case SET_ASSET:
      return action.payload
    default:
      return state
  }
}

export default assetReducer
