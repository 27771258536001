import React, { useEffect, useState } from "react";
import { ReactComponent as Message } from "../../components/assets/Message.svg";
import { ReactComponent as InformationCircle } from "../../components/assets/InformationCircle.svg";
import SwitchButton from "../../components/shared/switch";
import { APIServices } from "../../api";
import snack from "../../utils/snack";
import { AiOutlineLoading } from "react-icons/ai";
import Modal from "../../components/shared/modal";
import Tooltip from "@mui/material/Tooltip";

export default function Settings(props) {
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [minNumber, setMinNumber] = useState("");
  const [isError, setIsError] = useState(false);
  const [loadingMinBalance, setLoadingMinBalance] = useState(false);
  const [lastFieldLowBalance, setLastFieldLowBalance] = useState("");
  const [notifs, setNotifs] = useState({
    depositnotification: false,
    transactionnotification: false,
    lowbalancenotification: false,
    depositemail: false,
    lowbalanceemail: false,
    systemnotification: false,
    plannotification: false,
    smsplan: false,
    smsdeposit: false,
    smslowbalance: false,
    update_plan_email: false,
  });

  const handleChangeSwitch = (e, val) => {
    // console.log(e.target.checked);
    // console.log(val)
    setNotifs({
      ...notifs,
      [val]: e.target.checked,
    });
  };

  const handleChange = (val) => {
    if (val <= 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setMinNumber(val);
  };

  const hanldeSubmit = () => {
    setLoadingMinBalance(true);
    APIServices.updateMinBalance({ amount: Number(minNumber) })
      .then((res) => {
        if (res.data.success) {
          setMinNumber("");
          snack.success(res.data.message);
          setShowModal(false);
          props.getData();
          setTimeout(() => {
            setNotifs({
              ...notifs,
              [lastFieldLowBalance]: true,
            });
          }, 1000);
        }
      })
      .finally((e) => {
        setLoadingMinBalance(false);
      });
  };

  useEffect(() => {
    setNotifs({
      depositnotification: props.data.depositnotification,
      transactionnotification: props.data.transactionnotification,
      lowbalancenotification: props.data.lowbalancenotification,
      depositemail: props.data.depositemail,
      lowbalanceemail: props.data.lowbalanceemail,
      systemnotification: props.data.systemnotification,
      plannotification: props.data.plannotification,
      smslowbalance: props.data.smslowbalance,
      smsplan: props.data.smsplan,
      smsdeposit: props.data.smsdeposit,
      update_plan_email: props.data.update_plan_email,
    });
  }, [props.data]);

  const handleUpdateNotoification = () => {
    setLoadingUpdate(true);
    APIServices.handleNotificationSettings(JSON.stringify(notifs))
      .then((res) => {
        if (res.data.success) {
          snack.success(res.data.message);
        }
        if (
          !notifs.lowbalanceemail &&
          !notifs.lowbalancenotification &&
          !notifs.smslowbalance
        ) {
          APIServices.updateMinBalance({ amount: 0 }).then((res) => {
            if (res.data.success) {
              props.getData();
            }
          });
        }
        setLoadingUpdate(false);
      })
      .catch((er) => {
        setLoadingUpdate(false);
        snack.warning(er.data.message);
      });
  };

  const handleSwitchLowBalance = (e, val) => {
    if (e && props.lowBalance == 0) {
      setShowModal(true);
      setLastFieldLowBalance(val);
    }
  };

  return (
    <>
      <Modal
        onClose={() => {
          setShowModal(false);
        }}
        open={showModal}
        title={
          <span className="flex items-center gap-1">
            Set balance
            <Tooltip
              placement="top-start"
              title={
                <span className="bg-[#EAEAEA] text-black text-sm">
                  Set the amount to which you want to receive a message when
                  your balance falls below
                </span>
              }
              arrow
            >
              <InformationCircle className="cursor-pointer" />
            </Tooltip>
          </span>
        }
      >
        <div className="col-span-1 justify-between gap-2 md:pr-4 items-center">
          <div className="input-container relative">
            <span className="absolute top-2 right-4">TRX</span>
            <input
              className={`!text-left !pl-4 ${isError ? "error" : ""}`}
              placeholder="Amount"
              value={minNumber}
              type="number"
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
          </div>
          {loadingMinBalance ? (
            <span type="submit" className="submit-btn !mt-2">
              <AiOutlineLoading className="animate-spin" />
            </span>
          ) : (
            <button
              disabled={isError || !minNumber || minNumber == props?.lowBalance}
              onClick={hanldeSubmit}
              className="submit-btn !mt-3"
            >
              Set
            </button>
          )}
        </div>
      </Modal>
      <div className="flex gap-1 items-center">
        <Message />
        <span>Messages & Notifications</span>
      </div>
      <div className="mt-8 mb-12 gap-8">
        <div className="grid grid-cols-12">
          <div className="col-span-4"></div>
          <div className="col-span-2">Notification</div>
          <div className="col-span-2">Email</div>
          <div className="col-span-2 flex gap-3">
            SMS
            {!props.info.mobile && (
              <span
                onClick={() => props.setMobileModal()}
                className="text-[#2083FF] whitespace-nowrap select-none cursor-pointer"
              >
                Set Phone Number
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-12 min-h-16 items-center">
          <div className="col-span-4">Deposit</div>
          <div className="col-span-2">
            <SwitchButton
              checked={notifs.depositnotification}
              onChange={(e) => handleChangeSwitch(e, "depositnotification")}
            />
          </div>
          <div className="col-span-2">
            <SwitchButton
              checked={notifs.depositemail}
              onChange={(e) => handleChangeSwitch(e, "depositemail")}
            />
          </div>
          <div className="col-span-2">
            <SwitchButton
              disabled={!props.info.mobile}
              checked={notifs.smsdeposit}
              onChange={(e) => handleChangeSwitch(e, "smsdeposit")}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 min-h-16 items-center">
          <div className="col-span-4 flex whitespace-nowrap">
            <span>Low Balance</span>
            {props?.lowBalance > 0 && (
              <div className="flex gap-1">
                <span>({props.lowBalance} TRX)</span>
                <span
                  className="cursor-pointer text-[#2083FF]"
                  onClick={() => {
                    setShowModal(true);
                    setMinNumber(props.lowBalance);
                  }}
                >
                  Reset
                </span>
              </div>
            )}
          </div>
          <div className="col-span-2">
            <SwitchButton
              checked={notifs.lowbalancenotification}
              onChange={(e) => {
                handleChangeSwitch(e, "lowbalancenotification");
                handleSwitchLowBalance(
                  e.target.checked,
                  "lowbalancenotification"
                );
              }}
            />
          </div>
          <div className="col-span-2">
            <SwitchButton
              checked={notifs.lowbalanceemail}
              onChange={(e) => {
                handleChangeSwitch(e, "lowbalanceemail");
                handleSwitchLowBalance(e.target.checked, "lowbalanceemail");
              }}
            />
          </div>
          <div className="col-span-2">
            <SwitchButton
              disabled={!props.info.mobile}
              checked={notifs.smslowbalance}
              onChange={(e) => {
                handleChangeSwitch(e, "smslowbalance");
                handleSwitchLowBalance(e.target.checked, "smslowbalance");
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 min-h-16 items-center">
          <div className="col-span-4">Update Plan</div>
          <div className="col-span-2">
            <SwitchButton
              checked={notifs.plannotification}
              onChange={(e) => handleChangeSwitch(e, "plannotification")}
            />
          </div>
          <div className="col-span-2">
            <SwitchButton
              checked={notifs.update_plan_email}
              onChange={(e) => handleChangeSwitch(e, "update_plan_email")}
            />
          </div>
          <div className="col-span-2">
            <SwitchButton
              disabled={!props.info.mobile}
              checked={notifs.smsplan}
              onChange={(e) => handleChangeSwitch(e, "smsplan")}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 min-h-16 items-center">
          <div className="col-span-4">Transaction</div>
          <div className="col-span-2">
            <SwitchButton
              checked={notifs.transactionnotification}
              onChange={(e) => handleChangeSwitch(e, "transactionnotification")}
            />
          </div>
          <div className="col-span-2">
            {/* <SwitchButton
              checked={notifs.transactionnotification}
              onChange={(e) => handleChangeSwitch(e, "depositemail")}
            /> */}
          </div>
          <div className="col-span-2">
            {/* <SwitchButton
              checked={notifs.depositemail}
              onChange={(e) => handleChangeSwitch(e, "depositemail")}
            /> */}
          </div>
        </div>
        <div className="grid grid-cols-12 min-h-16 items-center">
          <div className="col-span-4">System</div>
          <div className="col-span-2">
            <SwitchButton
              checked={notifs.systemnotification}
              onChange={(e) => handleChangeSwitch(e, "systemnotification")}
            />
          </div>
          <div className="col-span-2">
            {/* <SwitchButton
              checked={notifs.lowbalanceemail}
              onChange={(e) => handleChangeSwitch(e, "lowbalanceemail")}
            /> */}
          </div>
          <div className="col-span-2">
            {/* <SwitchButton
              checked={notifs.depositemail}
              onChange={(e) => handleChangeSwitch(e, "depositemail")}
            /> */}
          </div>
        </div>
        <div className="flex justify-center pt-3">
          {loadingUpdate ? (
            <span type="submit" className="submit-btn max-w-32 !mt-0">
              <AiOutlineLoading className="animate-spin" />
            </span>
          ) : (
            <button
              className="mx-auto submit-btn max-w-32 !mt-0"
              onClick={handleUpdateNotoification}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </>
  );
}
