import Cookies from "js-cookie";
import { SnackbarProvider } from "notistack";
import React, { Suspense, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./components/store";
import "./components/styles/main.scss";
import i18n from "./i18n";
import ApiKeyPage from "./rotues/apikey";
import Dashboard from "./rotues/dashboard";
import Document from "./rotues/document";
import ForgetPassword from "./rotues/forgetPassword";
import IpPage from "./rotues/ips";
import Login from "./rotues/login";
import ProfilePage from "./rotues/profile";
import Register from "./rotues/resgister";
import SettingsPage from "./rotues/settings";
import TransactionsPage from "./rotues/transactions";
import { Snackbar } from "./utils/snack";
import NotificationsPage from "./rotues/notifications";
import AllRequests from "./rotues/requests";
import EstimatePage from "./rotues/estimate";
import DelegatePage from "./rotues/delegate";

const App = (props) => {
  const ProtectedRoute = () => {
    let user = useSelector((state) => state.user);
    let cookie = Cookies.get("paybymeUser");

    // console.log(user);
    return user ? <Outlet /> : <Navigate to="/login" />;
  };

  // loading component for suspense fallback
  const Loader = () => (
    <div className="App">
      {/* <img src={logo} className="App-logo" alt="logo" /> */}
      <div>loading...</div>
    </div>
  );
  useEffect(() => {
    return () => {
      localStorage.removeItem("user");
    };
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <PersistGate persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <SnackbarProvider
              maxSnack={5}
              classes={{
                variantSuccess: "snackbar snackbar-success",
                variantError: "snackbar snackbar-error",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Snackbar />
            </SnackbarProvider>
            <BrowserRouter>
              {/* <Auth> */}
              <Routes>
                <Route exact path="/" element={<Navigate to="/login" />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/login" element={<Login />} />
                <Route
                  exact
                  path="/forgetpassword"
                  element={<ForgetPassword />}
                />
                <Route exact path="/dashboard" element={<ProtectedRoute />}>
                  <Route exact path="/dashboard" element={<Dashboard />} />
                </Route>
                <Route exact path="/notifications" element={<ProtectedRoute />}>
                  <Route exact path="/notifications" element={<NotificationsPage />} />
                </Route>
                <Route exact path="/apikey" element={<ProtectedRoute />}>
                  <Route exact path="/apikey" element={<ApiKeyPage />} />
                </Route>
                <Route exact path="/transactions" element={<ProtectedRoute />}>
                  <Route
                    exact
                    path="/transactions"
                    element={<TransactionsPage />}
                  />
                </Route>
                <Route exact path="/preferences" element={<ProtectedRoute />}>
                  <Route exact path="/preferences" element={<ProfilePage />} />
                </Route>
                <Route exact path="/estimate" element={<ProtectedRoute />}>
                  <Route exact path="/estimate" element={<EstimatePage />} />
                </Route>
                <Route exact path="/userip" element={<ProtectedRoute />}>
                  <Route exact path="/userip" element={<IpPage />} />
                </Route>
                <Route exact path="/delegate" element={<ProtectedRoute />}>
                  <Route exact path="/delegate" element={<DelegatePage />} />
                </Route>
                {/* <Route exact path="/plans" element={<ProtectedRoute />}> */}
                  <Route exact path="/plans" element={<AllRequests />} />
                {/* </Route> */}
                {/* <Route exact path="/document" element={<ProtectedRoute />}>
                  <Route exact path="/document" element={<Document />} />
                </Route> */}
              </Routes>
            </BrowserRouter>
          </Provider>
        </I18nextProvider>
      </PersistGate>
    </Suspense>
  );
};

export default App;
