
export function commafy(num) {
  // if (!num) return ""
  // let tempnum = toEngNum(num)
  // tempnum = tempnum.replace(/[^0-9\.]+/g, "");
  // return tempnum.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  if (!num) return num
  let temp = num
  if (typeof temp === "string" && typeof num.replaceAll === "function") temp = temp.replaceAll(",", "")
  let parts = temp.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{4})+(?!\d))/g, " - ");
  return parts.join(".");
}

export function commafy2(num) {
  // if (!num) return ""
  // let tempnum = toEngNum(num)
  // tempnum = tempnum.replace(/[^0-9\.]+/g, "");
  // return tempnum.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  if (!num) return num
  let temp = num
  if (typeof temp === "string" && typeof num.replaceAll === "function") temp = temp.replaceAll(",", "")
  let parts = temp.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
